import React from "react";
import { store } from "../../Redux/store/index";
import { enqueueSnackbar } from "../../Redux/actions/actionNames";
import { Trans } from "react-i18next";
import { close_white } from "../../static";
import Typography from "../../design-system/lib/Typography";

export const NetworkError = (funcText, func, type) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type: type,
      options: {
      //  preventDuplicate: true,
        key: new Date().getTime() + Math.random(),
        variant: "error",
        persist: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#d32f2f",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
            </span>{" "}
            <button
              style={{
                cursor:"pointer",
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#d32f2f",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                color: "white",
                outline: "none",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
            </button>
          </div>
        )
      }
    })
  );
};

export const ApiOrderError = (funcText, func) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type: "ApiError",
      options: {
        preventDuplicate: true,
        key: new Date().getTime() + Math.random(),
        variant: "error",
        persist: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#d32f2f",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
            </span>{" "}
            <button
              style={{cursor:"pointer",
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#d32f2f",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                color: "white",
                outline: "none",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
            </button>
          </div>
        )
      }
    })
  );
};

export const SucceessFul = (title,type, funcText, func ,date=new Date().getTime() + Math.random()) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: true,
        key: date,
        variant: "success",
        persist: false,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#20a020",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "16px",
                float: "right",
                marginTop: "13px",
                marginRight: "10px" ,
                marginLeft:'10px'
              }}
            >
                <Trans i18nKey={title}></Trans>
            </span>{" "}
            <button
              style={{cursor:"pointer",
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#20a020",
                marginLeft: "9px",
                marginTop: "8px",
                marginBottom:'8px',
                border: "none",
                color: "white",
                float:'left',
                outline: "none",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
               <Trans i18nKey={funcText}></Trans>
            </button>
          </div>
        )
      }
    })
  );
  setTimeout(() => {
    store.dispatch({
      type: "removeNetworkErrorSnackBykey",
      key: date
    });
  }, 5000);
};

export const UnSucceessFul = (title,type, funcText, func,date=new Date().getTime() + Math.random()) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: true,
        key: date,
        variant: "success",
        persist: false,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#d32f2f",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
              {title}
            </span>{" "}
            <button
              style={{cursor:"pointer",
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#d32f2f",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                color: "white",
                outline: "none",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
              {funcText}
            </button>
          </div>
        )
      }
    })
  );
  setTimeout(() => {
    store.dispatch({
      type: "removeNetworkErrorSnackBykey",
      key: date
    });
  }, 5000);
};



export const UnSucceessFul_NOi18 = (title,type, funcText, func,date) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: false,
        key: date,
        variant: "success",
        persist: false,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#d32f2f",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                direction:'rtl',
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
              {title}
            </span>{" "}
            <button
              style={{
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#d32f2f",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                color: "white",
                outline: "none",
                float:"left",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
              {funcText}
            </button>
          </div>
        )
      }
    })
  );
  setTimeout(() => {
    store.dispatch({
      type: "removeNetworkErrorSnackBykey",
      key: date
    });
  }, 5000);
};


export const SucceessFul_NOi18 = (title,type, funcText, func,date=new Date().getTime() + Math.random()) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: true,
        key: date,
        variant: "success",
        persist: false,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#20a020",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                direction:'rtl',
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
              {title}
            </span>{" "}
            <button
              style={{
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#20a020",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                float:'left',
                color: "white",
                outline: "none",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
              {funcText}
            </button>
          </div>
        )
      }
    })
  );
  setTimeout(() => {
    store.dispatch({
      type: "removeNetworkErrorSnackBykey",
      key: date
    });
  }, 3000);
};


export const SucceessFul_i18 = (title,type, funcText, func,date=new Date().getTime() + Math.random()) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: true,
        key: date,
        variant: "success",
        persist: false,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#20a020",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                direction:'rtl',
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
              <Trans i18nKey={title}></Trans>
            </span>{" "}
            <button
              style={{
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#20a020",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                float:'left',
                color: "white",
                outline: "none",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
              <Trans i18nKey={funcText}></Trans>
            </button>
          </div>
        )
      }
    })
  );
  setTimeout(() => {
    store.dispatch({
      type: "removeNetworkErrorSnackBykey",
      key: date
    });
  }, 3000);
};







export const Warning_NOi18 = (title,type, funcText, func,date=new Date().getTime() + Math.random()) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: true,
        key: date,
        variant: "warning",
        persist: false,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#ffa500",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
            className="warning"
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                direction:'rtl',
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
              {title}
            </span>{" "}
            <button
              style={{
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#ffa500",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                color: "white",
                outline: "none",
                float:'left',
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
              {funcText}
            </button>
          </div>
        )
      }
    })
  );
  setTimeout(() => {
    store.dispatch({
      type: "removeNetworkErrorSnackBykey",
      key: date
    });
  }, 5000);
};






export const UnSucceessFul_i18 = (title,type, funcText, func,date) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: false,
        key: date,
        variant: "success",
        persist: false,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        content: key => (
          <div
            style={{
              width: "100%",
              minWidth: "304px",
              height: "50px",
              backgroundColor: "#d32f2f",
              borderRadius: "4px",
              boxShadow: "1px 7px 14px -5px rgba(0,0,0,0.2)"
            }}
            id={key}
          >
            <span
              style={{
                fontFamily: "IRANSansfanum,SFProRounded",
                color: "white",
                fontSize: "14px",
                float: "right",
                direction:'rtl',
                marginTop: "14px",
                marginRight: "20px"
              }}
            >
               <Trans i18nKey={title}></Trans>
            </span>{" "}
            <button
              style={{
                height: "33px",
                fontSize: "12px",
                backgroundColor: "#d32f2f",
                marginLeft: "9px",
                marginTop: "8px",
                border: "none",
                color: "white",
                outline: "none",
                float:"left",
                fontFamily: "IRANSansfanum,SFProRounded"
              }}
              onClick={func}
            >
              <Trans i18nKey={funcText}></Trans>
            </button>
          </div>
        )
      }
    })
  );
  setTimeout(() => {
    store.dispatch({
      type: "removeNetworkErrorSnackBykey",
      key: date
    });
  }, 5000);
};


export const UnSucceessFul_Title_Description_i18 = (title,description,type,func,date) => {
  store.dispatch(
    enqueueSnackbar({
      message: "",
      type,
      options: {
        preventDuplicate: false,
        key: date,
        variant: "success",
        persist: false,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        content: key => (
          <div
            style={{
              width: "320px",
              height: "46px",
              backgroundColor: "rgba(187, 18, 0, 1)",
              borderRadius: "8px",
              boxShadow: "0px 1px 6px 0px rgba(128, 128, 128, 0.16)",
              padding:"12px 16px 12px",
              display:"flex",
              flexDirection:"row",
              gap:"16px",
              justifyContent:"center",
              alignItems:"center"
            }}
            id={key}
          >

            <img src={close_white} style={{width:"24px",height:"24px", cursor:"pointer"}} onClick={()=>{
              store.dispatch({
                type: "removeNetworkErrorSnackBykey",
                key: date
              });
            }} />
            
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start",gap:"3px"}}>
              <Typography weight="Body_Middle_Medium" text={title} style={{color:"white"}}  />
              <Typography weight="Body_Tiny_Regular" text={description} style={{color:"white"}}  />

            </div>
          </div>
        )
      }
    })
  );

};
