import React, { useState } from "react";
import { getLogoTypeByUrl } from "../../../helper/module";
import ProfileMenu from "./ProfileMenu";
import Menu from "../../../static/ic_Hamburger_menu.svg";
import import2 from "../../../static/import2.svg";
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { OpenCloseMenu, Set_Table_ActiveOrderType } from "../../../Redux/actions/actionNames";
import { connect } from "react-redux";
import { NewOrderButton } from "./new-order-menu-button";
import styles from './header.module.css';
import Typography from "../../../design-system/lib/Typography";
import IconProvider from "../../../design-system/lib/IconProvider";
import ic_add from "../../../static/plus_white.svg";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import { withTranslation } from "react-i18next";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  openMenu = () => {
    this.props.dispatch(OpenCloseMenu(true))
  }
  render() {

    if (!isDesktop && !isTablet) {
      return <div className={clsx(styles.HeaderContainer, styles.HeaderContainerMobile , (this.props.orderTypes && this.props.orderTypes?.length == 1) && styles.HeaderContainerMobileOneOrderType )} style={this.props.style ? this.props.style : {}}>
        <img className={styles.HeaderMenu} src={Menu} onClick={this.openMenu} />
        <img className={styles.HeaderLogoType} src={global.config.logoTypeUrl} />
        {/* 
      <div style={{ float: 'inherit', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px' }}> </div>

      <div className={styles.orderTypeContainer} >
        <Link to={`/`} ><Typography weight={this.props.type == "Ondemand" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={"On Demand"} textColor={this.props.type == "Ondemand" ? "Primary" : "TextDark"} className={this.props.type == "Ondemand" ? styles.orderTypeActive : styles.orderType} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("Ondemand")) }} /></Link>
        <Link to={`/table`} ><Typography weight={this.props.type == "PickupDelivery" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={"P_Hub_D"} textColor={this.props.type == "PickupDelivery" ? "Primary" : "TextDark"} className={this.props.type == "PickupDelivery" ? styles.orderTypeActive : styles.orderType} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("PickupDelivery")) }} /></Link>
        

      </div> */}




        <ProfileMenu />
        <div style={{ float: 'inline-end', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px', marginInlineEnd: '16px' }}> </div>
        <NewOrderButton noIcon={true} />

       {(this.props.orderTypes && this.props.orderTypes?.length == 2) &&  <div className={styles.rowContainer}>
          <div className={clsx(styles.orderTypeContainer, styles.orderTypeContainerMobile)} >
            <Link style={{ width: "calc(50% - 10px)" }} to={`/`} ><Typography weight={this.props.type == "Ondemand" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={this.props.t("Ondemand")} textColor={this.props.type == "Ondemand" ? "Primary" : "TextDark"} className={this.props.type == "Ondemand" ? styles.orderTypeActive +" orderTypesHeaderTabActive" : styles.orderType+" orderTypesHeaderTabNotActive"} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("Ondemand")) }} style={{ width: "calc(100% - 32px)" }} /></Link>
            <Link style={{ width: "calc(50% - 10px)" }} to={`/table`} ><Typography weight={this.props.type == "PickupDelivery" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={this.props.t("P_Hub_D")} textColor={this.props.type == "PickupDelivery" ? "Primary" : "TextDark"} className={this.props.type == "PickupDelivery" ? styles.orderTypeActive+" orderTypesHeaderTabActive" : styles.orderType+" orderTypesHeaderTabNotActive"} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("PickupDelivery")) }} style={{ width: "calc(100% - 32px)" }} /></Link>
          </div>
        </div>}


      </div>
    } else {
      return <div className={styles.HeaderContainer} style={this.props.style ? this.props.style : {}}>
        <img className={styles.HeaderMenu} src={Menu} onClick={this.openMenu} />
        <img className={styles.HeaderLogoType} src={global.config.logoTypeUrl} />

        <div style={{ float: 'inherit', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px' }}> </div>

        {(this.props.orderTypes && this.props.orderTypes?.length == 2) && <div className={styles.orderTypeContainer} >
          <Link to={`/`} ><Typography weight={this.props.type == "Ondemand" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={this.props.t("Ondemand")} textColor={this.props.type == "Ondemand" ? "Primary" : "TextDark"} className={this.props.type == "Ondemand" ? styles.orderTypeActive+" orderTypesHeaderTabActive" : styles.orderType+" orderTypesHeaderTabNotActive"} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("Ondemand")) }} /></Link>
          <Link to={`/table`} ><Typography weight={this.props.type == "PickupDelivery" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={this.props.t("P_Hub_D")} textColor={this.props.type == "PickupDelivery" ? "Primary" : "TextDark"} className={this.props.type == "PickupDelivery" ? styles.orderTypeActive+" orderTypesHeaderTabActive" : styles.orderType+" orderTypesHeaderTabNotActive"} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("PickupDelivery")) }} /></Link>
        </div> }



        <ProfileMenu />
        <div style={{ float: 'inline-end', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px', marginInlineEnd: '16px' }}> </div>
        <NewOrderButton noIcon={false} />

      </div>
    }

    return <div className={styles.HeaderContainer} style={this.props.style ? this.props.style : {}}>
      <img className={styles.HeaderMenu} src={Menu} onClick={this.openMenu} />
      <img className={styles.HeaderLogoType} src={global.config.logoTypeUrl} />

      <div style={{ float: 'inherit', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px' }}> </div>

      <div className={styles.orderTypeContainer} >
        <Link to={`/`} ><Typography weight={this.props.type == "Ondemand" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={"On Demand"} textColor={this.props.type == "Ondemand" ? "Primary" : "TextDark"} className={this.props.type == "Ondemand" ? styles.orderTypeActive : styles.orderType} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("Ondemand")) }} /></Link>
        <Link to={`/table`} ><Typography weight={this.props.type == "PickupDelivery" ? "Body_Middle_Bold" : "Body_Middle_Medium"} text={"P_Hub_D"} textColor={this.props.type == "PickupDelivery" ? "Primary" : "TextDark"} className={this.props.type == "PickupDelivery" ? styles.orderTypeActive : styles.orderType} onClick={() => { this.props.dispatch(Set_Table_ActiveOrderType("PickupDelivery")) }} /></Link>


      </div>




      <ProfileMenu />
      <div style={{ float: 'inline-end', width: '1px', height: '32px', background: '#F4F4F7', marginTop: '12px', marginInlineStart: '16px', marginInlineEnd: '16px' }}> </div>
      <NewOrderButton />

    </div>;
  }
}


const mapStateToProps = (state) => ({
  type: state.Table.filters.type,
  orderTypes: state.SharedData.WebSettings ? state.SharedData.WebSettings.orderTypes : null,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
