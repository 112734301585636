import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../static/IconProvider/arrow_down_bold.svg";
import { ReactComponent as Balance } from "../../../../static/mutual-wallet-balance.svg";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { New_Travel_PaymentMethod } from "../../../../Redux/actions/actionNames";
import { ReactComponent as Plus } from "../../../../static/IconProvider/add-square.svg";
import { get_customerPannel_type, get_Id, get_lang, Get_Permisssion } from "../../../../helper/UserData/userdate";
import { ReactComponent as Walletx } from "../../../../static/IconProvider/walletx.svg";
import { ReactComponent as SenderCashic } from "../../../../static/IconProvider/dollar-square.svg";
import { ReactComponent as ReceiverCashic } from "../../../../static/IconProvider/by_receiver.svg";
import customericbalancecard from "../../../../static/customer-ic-balance-card.svg";
import Dialog from "@material-ui/core/Dialog";
import { Api_Get_Payment_Methods_AGW, Api_New_Payment_Order_AGW } from "../../../../helper/api/ApiGateway";
import Wallet from "../../../Main/Menu/wallet";
import Stripe_Add_Card from "./changeMethod/stripe";
import PaymentIcon from "react-payment-icons-inline";
import styles from '../index.module.css';
import Typography from "../../../../design-system/lib/Typography";
import IconProvider from "../../../../design-system/lib/IconProvider";
import IconProviderSvgModifier from "../../../../design-system/lib/IconProviderSvgModifier";
import Tag from "../../../../design-system/lib/Tag";
import clsx from 'clsx'
import { isEqual } from "lodash";
import { UnSucceessFul_Title_Description_i18 } from "../../../../helper/Notification/sendNotification";
import { snack } from "../../../../helper/module/SnackbarUtilsConfigurator";


class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cards: [],
      cardsLoaded:false ,
      showpoperMethod: false,
      showpoperSide: false,
      Inc_Balance: false,
      amountEntered: "",
      selectedGateWay: null,
      paymentSide: [
        {
          i18: "SenderWallet",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Wallet",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "SenderCash",
          data: {
            paymentSide: "Sender",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
        {
          i18: "ReceiverCash",
          data: {
            paymentSide: "Receiver",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          },
        },
      ],
    };
    this.paymentSide = React.createRef();
    this.getCards = this.getCards.bind(this)
    this.checkWalletDisable = this.checkWalletDisable.bind(this)
    this.getSenderWalletText = this.getSenderWalletText.bind(this)

  }


  getSenderWalletText = (i18) => {
    if(get_customerPannel_type() == "business" && this.props.isInvoiceActive){
      return this.props.t("account")
    }else{
      return this.props.t(i18)
    }
  };

  bussinessisInvoiceActive = () => {
    if(get_customerPannel_type() == "business" && this.props.isInvoiceActive){
      return true
    }else{
      return false
    }
  };


  getImageOfPaymentMeyhod = (name) => {
    if (name == "SenderWallet") {
      return <IconProviderSvgModifier Icon={Walletx} size='16' />
    } else if (name == "SenderCash") {
      return <IconProviderSvgModifier Icon={SenderCashic} size='16' />
    } else if (name == "ReceiverCash") {
      return <IconProviderSvgModifier Icon={ReceiverCashic} size='16'  />
    }
  }

  handleshowpoperPaymentSide = (bool) => {
    this.setState({
      showpoperSide: bool,
    });
  };

  handleAmountEntered = (e, number) => {
    this.setState({
      amountEntered: number ? number : e.target.value,
    });
  }

  handleShowIncBalance = (bool) => {
    this.setState({
      showpoperSide: false,
      showpoperMethod: false,
    });
    
    this.setState({
      Inc_Balance: bool,
      amountEntered: "",
    });
  };

  handleSelectedItem = (item) => {
    if(isEqual(this.state.paymentSide[0] , item)){
      if(this.checkWalletDisable()){

             snack.ErrorTitleDescSnackbar(JSON.stringify({
                "title": this.props.t("Walletbalanceislow"),
                "description": this.props.t("Walletbalanceislowd")
              }))
      }else{
        this.props.dispatch(New_Travel_PaymentMethod(item.data));
        this.setState({
          showpoperSide: false,
          showpoperMethod: false,
        });
      }

    }else{
          this.props.dispatch(New_Travel_PaymentMethod(item.data));
    this.setState({
      showpoperSide: false,
      showpoperMethod: false,
    });
    }
    this.props.dispatch({
      type: "removeNetworkErrorSnack",
      key: "networkError",
    });
  };

  handleSelectedItemStripe = (item) => {
    this.props.dispatch(New_Travel_PaymentMethod({
      paymentSide: "Sender",
      paymentMethod: "Card",
      paymentProvider: "stripe",
      cardInfo: item,
      paymentMethodId: item.id,

    }));
    this.setState({
      showpoperSide: false,
      showpoperMethod: false,
    });
  };


  getCards() {
    Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
  }


  setCards = (cards) => {
    this.setState({
      cards:cards,
      cardsLoaded:true
    },()=>{
      if (this.props.price.price && (global.config.stripePublicApiKey && this.state.cards.length > 0)) {
        this.props.dispatch(New_Travel_PaymentMethod({
          paymentSide: "Sender",
          paymentMethod: "Card",
          paymentProvider: "stripe",
          cardInfo: this.state.cards[0],
          paymentMethodId: this.state.cards[0].id,
    
        }));
      }else if (this.props.price.price && ((this.props.balance - this.props.price.price.price + (this.props.price.price.promoAmount ? this.props.price.price.promoAmount : 0)) >= this.props.minimumBalance)) {
        this.props.dispatch(New_Travel_PaymentMethod({
          paymentSide: "Sender",
          paymentMethod: "Wallet",
          paymentProvider: null,
          cardInfo: null,
          paymentMethodId: null,
        }));
      }else if(this.props.price.price &&  ((this.props.balance - this.props.price.price.price + (this.props.price.price.promoAmount ? this.props.price.price.promoAmount : 0)) < this.props.minimumBalance)){
        if (this.props.serviceSettings.isCashBySenderActive) {
          //sender cash
          this.props.dispatch(New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
            where: "263"
          }));
  
        } else if (this.props.serviceSettings.enableReceiverCash) {
          //Receiver cash
          this.props.dispatch(New_Travel_PaymentMethod({
            paymentSide: "Receiver",
            paymentMethod: "Cash",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          }));
        }
      }
  
  
    })
  }



  checkWalletDisable = () => {

    if(this.props.price.done){
      if (Number(this.props.balance) >= Number(this.props.price.price.price) || ((this.props.balance - this.props.price.price.price + (this.props.price.price.promoAmount ? this.props.price.price.promoAmount : 0)) >= this.props.minimumBalance)) {
        return false
      } else {
        return true
      }
    }else {
      return true
    }
    
  }



  WalletCheck = (item) => {
    if (
      (item
        ? item.paymentSide + item.paymentMethod
        : this.props.paymentSide + this.props.paymentMethod) == "SenderWallet"
    ) {
      if (this.props.price.done && this.props.price.price.price )  {
        if (this.WalletBalance(this.props, item)) {
          return "green";
        } else {
          return "red";
        }
      } else {
        return "grey";
      }
    } else {
      return "grey";
    }
  };

  WalletBalance = (props, item) => {
      if (
        (item
          ? item.paymentSide + item.paymentMethod
          : props.paymentSide + props.paymentMethod) == "SenderWallet"
      ) {
        if ( (props.balance - props.price.price.price + (props.price.price.promoAmount ? props.price.price.promoAmount : 0)) >= props.minimumBalance) {
          return true
        } else {
          return Number(props.price.price.price - (props.price.price.promoAmount ? props.price.price.promoAmount : 0)) <=
            Number(props.balance)
        }
      } else {
        return Number(props.price.price.price - (props.price.price.promoAmount ? props.price.price.promoAmount : 0)) <=
          Number(props.balance)
      }
 
  };


  hanlePay = () => {
    if (this.state.amountEntered.length > 0) {
      this.saveReducerInLocalStorage()
      Api_New_Payment_Order_AGW(this.props.dispatch, this.removeEmpty({
        "customerId": get_Id(),
        "amount": this.state.amountEntered,
        "deviceType": "web",
        "packageName": null,
        "gateway": this.state.selectedGateWay || this.props.payment[0].gateway
      })
      )
    }
  }

  neededBalance = () => {
    return (Number(
      this.props.price.price.price - (this.props.price.price.promoAmount ? this.props.price.price.promoAmount : 0)
    ) - Number(this.props.balance))
  }

  removeEmpty = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
  }

  saveReducerInLocalStorage = () => {
    let data = {
      driver: {
        auto_activeVehicleType: this.props.auto_activeVehicleType,
        options: this.props.driver_options
      },
      dropOffs: {
        firstDropOff: this.props.NRdropOffAddress,
        otherDropOffs: this.props.NRotherDropOffs
      },
      service: {
        activeVehicleType: this.props.active_service,
        options: this.props.service_options
      },
      pickup: this.props.NRpickupAddress,
    }
    localStorage.setItem("draft", JSON.stringify(data));
  }



  componentDidMount() {

    if(this.state.cardsLoaded){
    if (this.props.price.price && (global.config.stripePublicApiKey && this.state.cards.length > 0 && this.state.cardsLoaded)) {
      this.props.dispatch(New_Travel_PaymentMethod({
        paymentSide: "Sender",
        paymentMethod: "Card",
        paymentProvider: "stripe",
        cardInfo: this.state.cards[0],
        paymentMethodId: this.state.cards[0].id,
  
      }));
    }else if (this.props.price.price && ((this.props.balance - this.props.price.price.price + (this.props.price.price.promoAmount ? this.props.price.price.promoAmount : 0)) >= this.props.minimumBalance)) {
      this.props.dispatch(New_Travel_PaymentMethod({
        paymentSide: "Sender",
        paymentMethod: "Wallet",
        paymentProvider: null,
        cardInfo: null,
        paymentMethodId: null,
      }));
    }else if(this.props.price.price &&  ((this.props.balance - this.props.price.price.price + (this.props.price.price.promoAmount ? this.props.price.price.promoAmount : 0)) < this.props.minimumBalance)){
      if (this.props.serviceSettings.isCashBySenderActive) {
        //sender cash
        this.props.dispatch(New_Travel_PaymentMethod({
          paymentSide: "Sender",
          paymentMethod: "Cash",
          paymentProvider: null,
          cardInfo: null,
          paymentMethodId: null,
          where: "263"
        }));

      } else if (this.props.serviceSettings.enableReceiverCash) {
        //Receiver cash
        this.props.dispatch(New_Travel_PaymentMethod({
          paymentSide: "Receiver",
          paymentMethod: "Cash",
          paymentProvider: null,
          cardInfo: null,
          paymentMethodId: null,
        }));
      }
    }



    }


 

    if ((global.config.stripePublicApiKey && global.config.stripePublicApiKey.length > 0) && get_Id() && this.state.cards.length == 0) {
      Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
    }else{
      this.setState({
        cardsLoaded : true
      })
    }

  }


  componentWillReceiveProps(nextProps) {
 
try {
      if(nextProps.paymentMethod == null ) {
        if (nextProps.price.price && (global.config.stripePublicApiKey && this.state.cards.length > 0 && this.state.cardsLoaded)) {
          nextProps.dispatch(New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Card",
            paymentProvider: "stripe",
            cardInfo: this.state.cards[0],
            paymentMethodId: this.state.cards[0].id,
      
          }));
        }else if (nextProps.price.price && (nextProps.balance - nextProps.price.price.price + (nextProps.price.price.promoAmount ? nextProps.price.price.promoAmount : 0 )) >= nextProps.minimumBalance) {
          nextProps.dispatch(New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          }));
        }else if(nextProps.price.price && (nextProps.balance - nextProps.price.price.price + (nextProps.price.price.promoAmount ? nextProps.price.price.promoAmount : 0 )) < nextProps.minimumBalance){
          if (nextProps.serviceSettings.isCashBySenderActive) {
            //sender cash
            nextProps.dispatch(New_Travel_PaymentMethod({
              paymentSide: "Sender",
              paymentMethod: "Cash",
              paymentProvider: null,
              cardInfo: null,
              paymentMethodId: null,
              where: "341"
            }));
    
          } else if (nextProps.serviceSettings.enableReceiverCash) {
            //sender cash
            nextProps.dispatch(New_Travel_PaymentMethod({
              paymentSide: "Receiver",
              paymentMethod: "Cash",
              paymentProvider: null,
              cardInfo: null,
              paymentMethodId: null,
            }));
          }
        }
  
    
      }
} catch (error) {
  
}

    // if(nextProps.OpenDialoge && !this.props.OpenDialoge){
    //   if (get_customerPannel_type() == "business") {
    //     if (Get_Permisssion().canWalletBeNegative) {
    //       nextProps.dispatch(New_Travel_PaymentMethod({
    //         paymentSide: "Sender",
    //         paymentMethod: "Wallet",
    //         paymentProvider: null,
    //         cardInfo: null,
    //         paymentMethodId: null,
    //       }));
    //     } else {
    //       if (nextProps.serviceSettings.isCashBySenderActive) {
    //         //sender cash
    //         nextProps.dispatch(New_Travel_PaymentMethod({
    //           paymentSide: "Sender",
    //           paymentMethod: "Cash",
    //           paymentProvider: null,
    //           cardInfo: null,
    //           paymentMethodId: null,
    //           where: "257"
    //         }));
  
    //       } else if (nextProps.serviceSettings.enableReceiverCash) {
    //         //sender cash
    //         nextProps.dispatch(New_Travel_PaymentMethod({
    //           paymentSide: "Receiver",
    //           paymentMethod: "Cash",
    //           paymentProvider: null,
    //           cardInfo: null,
    //           paymentMethodId: null,
    //         }));
    //       }
    //     }
  
    //   }else {
    //     if (nextProps.serviceSettings.isCashBySenderActive) {
    //       //sender cash
    //       nextProps.dispatch(New_Travel_PaymentMethod({
    //         paymentSide: "Sender",
    //         paymentMethod: "Cash",
    //         paymentProvider: null,
    //         cardInfo: null,
    //         paymentMethodId: null,
    //         where: "257"
    //       }));
  
    //     } else if (nextProps.serviceSettings.enableReceiverCash) {
    //       //sender cash
    //       nextProps.dispatch(New_Travel_PaymentMethod({
    //         paymentSide: "Receiver",
    //         paymentMethod: "Cash",
    //         paymentProvider: null,
    //         cardInfo: null,
    //         paymentMethodId: null,
    //       }));
    //     }
    //   }
  
    // }

    // if ("SenderWallet" == this.props.paymentSide + this.props.paymentMethod && get_customerPannel_type() == "business" && 
    //  !Get_Permisssion().canWalletBeNegative &&  Number(nextProps.balance) <= 0 ) {

    //   if (this.props.serviceSettings.isCashBySenderActive) {
    //     //sender cash
    //     this.props.dispatch(New_Travel_PaymentMethod({
    //       paymentSide: "Sender",
    //       paymentMethod: "Cash",
    //       paymentProvider: null,
    //       cardInfo: null,
    //       paymentMethodId: null,
    //       where:"257"
    //     }));

    //   } else if (this.props.serviceSettings.enableReceiverCash) {
    //     //sender cash
    //     this.props.dispatch(New_Travel_PaymentMethod({
    //       paymentSide: "Receiver",
    //       paymentMethod: "Cash",
    //       paymentProvider: null,
    //       cardInfo: null,
    //       paymentMethodId: null,
    //     }));
    //   }
    // }else if(get_customerPannel_type() != "business" && "SenderWallet" == this.props.paymentSide + this.props.paymentMethod && ["grey" , "red"].indexOf(this.WalletCheck() ) > -1 ){
    //   if (this.props.serviceSettings.isCashBySenderActive) {
    //     //sender cash


    //     this.props.dispatch(New_Travel_PaymentMethod({
    //       paymentSide: "Sender",
    //       paymentMethod: "Cash",
    //       paymentProvider: null,
    //       cardInfo: null,
    //       paymentMethodId: null,
    //       where:"257"
    //     }));



    //   } else if (this.props.serviceSettings.enableReceiverCash) {
    //     //sender cash
    //     this.props.dispatch(New_Travel_PaymentMethod({
    //       paymentSide: "Receiver",
    //       paymentMethod: "Cash",
    //       paymentProvider: null,
    //       cardInfo: null,
    //       paymentMethodId: null,
    //     }));

    //   }
    // }


    if ((global.config.stripePublicApiKey && global.config.stripePublicApiKey.length > 0) && get_Id() && this.state.cards.length == 0) {
      Api_Get_Payment_Methods_AGW(this.props.dispatch, this.setCards)
    }else{
      this.setState({
        cardsLoaded : true
      })
    }


  }


  render() {
    const { t } = this.props;
    return (
      <React.Fragment>


        <div className={styles.changeMethodContainer} onClick={() => this.handleshowpoperPaymentSide(true)} ref={this.paymentSide}
          id={this.props.id}
        >
          <div className={styles.changeMethodIconContainer} >
            {"SenderCard" == this.props.paymentSide + this.props.paymentMethod ? (
              <PaymentIcon
              icon={this.props.cardInfo.brand}
              className={styles.changeMethodIconContainer}
              />
            ) : (
              this.getImageOfPaymentMeyhod(this.props.paymentSide + this.props.paymentMethod)
            )}
          </div>

          <div className={styles.changeMethodTextsContainer} >

            <Typography weight="Body_Middle_Bold" text={this.props.priceText} style={{ lineHeight: "16.71px" }} />
            <Typography style={{ lineHeight: "14.32px" }} weight="Subtitle_Tiny_Regular" text={"SenderCard" == this.props.paymentSide + this.props.paymentMethod ? `**** ${this.props.cardInfo.lastNumbers}` : (this.props.paymentSide ? <Trans i18nKey={(this.props.paymentSide + this.props.paymentMethod == "SenderWallet" ? this.getSenderWalletText("SenderWallet") : this.props.paymentSide + this.props.paymentMethod)}></Trans> : <Trans i18nKey="PleaseSelect"></Trans>)} />



          </div>

          <img className={`fowiaaroow ${this.state.showpoperSide && "rotate180"}`} src={arrow} />


        </div>




        {/* <clickableservice
          className={`clickableChangeMethod marginR10  ${
            this.WalletCheck() == "green"
              ? "SenderWallet"
              : this.WalletCheck() == "red"
              ? "SenderWalletred"
              : ""
          }  ${this.WalletCheck() == "red" ? "clickableserviceRed" : ""}
          `}
          style={{
            marginRight: "0px",
          }}
          ref={this.paymentSide}
          id={this.props.id}
          onClick={() => this.handleshowpoperPaymentSide(true)}
        >
          {
            <img
              src={arrow}
              className={`${this.state.showpoperSide && "rotate180"} ${
                this.WalletCheck() == "red" ? "mrt10" : ""
              }`}
            />
          }
          <span
            className={`paymentmethodspan ${this.props.paymentSide + this.props.paymentMethod} ${
              this.props.paymentSide + this.props.paymentMethod !=
              "SenderWallet"
                ? "paymentmethodspanoedited"
                : this.WalletCheck() != "red"
                ? "paymentmethodspanoedited"
                : ""
            }`}
          >
            {" "}
            <Trans i18nKey={this.props.paymentSide + this.props.paymentMethod}>
              {" "}
            </Trans>
          </span>
          {this.props.paymentSide + this.props.paymentMethod ==
          "SenderWallet" ? (
            this.WalletCheck() == "red" ? (
              <span className="lowBalance">
                {" "}
                <Trans i18nKey={"lowBalance"}></Trans>
              </span>
            ) : null
          ) : null}
        </clickableservice> */}

        <Popper
          open={this.state.showpoperSide }
          anchorEl={this.paymentSide.current}
          role={undefined}
          placement={"top"}
          style={{
            zIndex: 7,
            marginTop: "4px",
            borderRadius: "8px",
            boxShadow: '0px 1px 12px 0px rgba(128, 128, 128, 0.2)',
            marginInlineStart: "11px"
          }}
          transition
          // css={styles.dwdwdwd}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              timeout={{ enter: 150, exit: 150 }}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={styles.paymentMethodsPopper}>
                <ClickAwayListener
                  onClickAway={() => this.handleshowpoperPaymentSide(false)}
                >
                  <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

                  {this.state.cards.map((card) => {
                      return <div
                      className={styles.methodMethodsItem}
                        // style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}
                        onClick={() =>
                          this.handleSelectedItemStripe(card)
                        }

                      >
                        <PaymentIcon
                          icon={card.brand}
                          style={{ width: '16px' }}
                        />
                        <Typography weight={("SenderCard" ==
                           this.props.paymentSide + this.props.paymentMethod
                         ) && this.props.paymentMethodId == card.id ? "Body_Middle_Bold" : "Body_Middle_Regular"} text={`**** ${card.lastNumbers}`} />
                      </div>
                    })}


                    {(global.config.stripePublicApiKey && this.state.cards.length == 0) && <Stripe_Add_Card onClose={this.getCards} />}
{(global.config.stripePublicApiKey && this.state.cards.length == 0) &&  <div style={{ height: "1px", width: "100%", backgroundColor: "rgba(244, 244, 247, 1)" }}>
                    </div>}

                    <div  className={clsx(styles.methodMethodsItem   )}>
                      <IconProviderSvgModifier   onClick={() =>{
                       this.handleSelectedItem(this.state.paymentSide[0])}
                        } Icon={Walletx} size='16' />
                      <Typography   onClick={() =>{
                       this.handleSelectedItem(this.state.paymentSide[0])}
                        }  weight={this.state.paymentSide[0].i18 ==
                        this.props.paymentSide + this.props.paymentMethod ? "Body_Middle_Bold" : "Body_Middle_Regular"} text={this.getSenderWalletText("SenderWallet")} />

                      <Tag size="Small"   onClick={() =>{
                       this.handleSelectedItem(this.state.paymentSide[0])}
                        } text={this.props.price.price.currencyCode
                        ? Number(this.props.balance).toLocaleString(
                          get_lang(),
                          {
                            style: "currency",
                            currency: this.props.price.price.currencyCode,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )
                        : this.props.balance} backgroundColor="GrayUltraLight" weight="Body_Tiny_Regular" style={{ marginInlineStart: "-4px" }} />

                      <div className={styles.TopupContainer} onClick={() => this.handleShowIncBalance(true)}>
                        <IconProviderSvgModifier Icon={Plus} size='16' />
                        <Typography weight="Body_Tiny_Bold" textColor="TextDark" className={styles.TopupColor} text={"Top up"} />


                      </div>
           
                      {/* { this.checkWalletDisable() ? (
                      <div>
                        <Typography weight="Body_Tiny_Regular" text={this.props.t("balanceError")} />
                      </div>
                    ) : null} */}


                 

                    </div>
                    

                   {this.WalletCheck() == "red" && <div style={{flexWrap:"wrap",height:"auto",paddingTop:"8px"}} className={styles.methodMethodsItem} >
                      <Typography weight="Body_Tiny_Regular" text={this.props.t("balanceError")} style={{maxWidth:"193px"}} />

                      </div>}

                   {this.props.serviceSettings?.isCashBySenderActive && <div className={styles.methodMethodsItem} onClick={() =>
                          this.handleSelectedItem(this.state.paymentSide[1])
                        }>
                      <IconProviderSvgModifier Icon={SenderCashic} size='16' />

                      <Typography weight={this.state.paymentSide[1].i18 ==
                        this.props.paymentSide + this.props.paymentMethod ? "Body_Middle_Bold" : "Body_Middle_Regular"} text={this.props.t("SenderCash")} />
                    </div>}

                 

                   {this.props.serviceSettings?.enableReceiverCash && <div className={styles.methodMethodsItem} onClick={() =>
                          this.handleSelectedItem(this.state.paymentSide[2])
                        }>
                      <IconProviderSvgModifier Icon={ReceiverCashic} size='16' />
                      <Typography weight={this.state.paymentSide[2].i18 ==
                        this.props.paymentSide + this.props.paymentMethod ? "Body_Middle_Bold" : "Body_Middle_Regular"} text={this.props.t("ReceiverCash")} />
                    </div>}

         


                    {/*

                    {/* <div className={styles.methodMethodsItem}>
                      <ReceiverCashic /> 
                    <Typography weight="Body_Middle_Regular" text={this.props.t("ReceiverCash")} />

                  </div> */}

             

                   {(global.config.stripePublicApiKey && this.state.cards.length > 0) &&  <div style={{ height: "1px", width: "100%", backgroundColor: "rgba(244, 244, 247, 1)" }}>
                    </div>}
                    {(global.config.stripePublicApiKey && this.state.cards.length > 0) && <Stripe_Add_Card onClose={this.getCards} />}

                  </div>
                  {/* {  !this.bussinessisInvoiceActive() &&
                    <div className="BalanceContainer">
                      <WalletSvg className="balanceicon" />
                      <PlusSvg className="increaseBalance"
                       style={{
                        transform: 'scale(0.7)',
                        marginTop: '-2px',
                        marginInlineStart: '-8px',
                        width:"22px" ,
                        height :"22px"
                  }}
                        onClick={() => this.handleShowIncBalance(true)} />
                      <span className="BalanceText">
                        {" "}
                        <Trans i18nKey={"Balance"}> </Trans>
                      </span>
                      <span className="BalanceNumberText">
                        {" "}
                        {this.props.balancecurrencyCode
                          ? Number(this.props.balance).toLocaleString(
                            get_lang(),
                            {
                              style: "currency",
                              currency: this.props.balancecurrencyCode,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                          : this.props.balance}
                      </span>
                    </div>} */}


                  {/* <div
                      className={`MethodsItem ${this.checkWalletDisable() ? "paymentMethodDisable" :
                        this.state.paymentSide[0].i18 ==
                          this.props.paymentSide + this.props.paymentMethod
                          ? "MethodsItemActive"
                          : ""
                        }

                      ${this.WalletCheck() == "red" && "redborder"}`}
                      
                      style={
                      this.state.paymentSide[0].i18 ==
                        this.props.paymentSide + this.props.paymentMethod
                        ? { border: `1px solid ${global.config.colors.secondaryColor}`,
                        color: global.config.colors.secondaryColor,
                        backgroundColor: global.config.colors.secondaryUltraLight}
                        :  {}}
                      onClick={() =>
                        !this.checkWalletDisable() && this.handleSelectedItem(this.state.paymentSide[0])
                      }
                    >
                      <CustomerIcWalletSvg className="img c7921369" />
                      <span>
                        {this.getSenderWalletText("SenderWallet")}
                      </span>
                    </div> */}

                  {/* {this.WalletCheck() == "red" ? (
                      <span className="balanceError">
                        {" "}
                        <Trans i18nKey={"balanceError"}> </Trans>{" "}
                      </span>
                    ) : null} */}

                  {/* {this.props.serviceSettings.isCashBySenderActive && (
                      <div
                        className={`MethodsItem ${this.state.paymentSide[1].i18 ==
                            this.props.paymentSide + this.props.paymentMethod
                            ? "MethodsItemActive"
                            : ""
                          }`}

                          style={
                            this.state.paymentSide[1].i18 ==
                              this.props.paymentSide + this.props.paymentMethod
                              ? { border: `1px solid ${global.config.colors.secondaryColor}`,
                              color: global.config.colors.secondaryColor,
                              backgroundColor: global.config.colors.secondaryUltraLight}
                              :  {}}
                        onClick={() =>
                          this.handleSelectedItem(this.state.paymentSide[1])
                        }
                      >
                        <IcCashSvg className="img w9647" />
                        <span>
                          {" "}
                          <Trans i18nKey={"SenderCash"}> </Trans>{" "}
                        </span>
                      </div>
                    )} */}

                  {/* {this.props.serviceSettings.isCashBySenderActive && (
                      <div
                        className={`MethodsItem ${this.state.paymentSide[2].i18 ==
                            this.props.paymentSide + this.props.paymentMethod
                            ? "MethodsItemActive"
                            : ""
                          }`}

                          style={
                            this.state.paymentSide[2].i18 ==
                              this.props.paymentSide + this.props.paymentMethod
                              ? { border: `1px solid ${global.config.colors.secondaryColor}`,
                              color: global.config.colors.secondaryColor,
                              backgroundColor: global.config.colors.secondaryUltraLight}
                              :  {}}
                        onClick={() =>
                          this.handleSelectedItem(this.state.paymentSide[2])
                        }
                      >
                        <CustomerIcByReceiverSvg className="img wr98364" />
                        <span>
                          {" "}
                          <Trans i18nKey={"ReceiverCash"}> </Trans>{" "}
                        </span>
                      </div>
                    )} */}






                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>


        <Wallet ShowComponent={false} OpenDialoge={this.state.Inc_Balance} prefered={this.WalletCheck() == "red" ? this.neededBalance() : 0} onClose={() => this.handleShowIncBalance(false)} />

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  OpenDialoge: state.NewOrder.OpenDialoge,

  price: state.NewOrder.newOrder.price,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  balance: state.NewOrder.newOrder.balance.balance,
  balancecurrencyCode: state.NewOrder.newOrder.balance.currencyCode,
  serviceSettings: state.NewOrder.newOrder.service.activeVehicleType
    ? state.NewOrder.newOrder.service.activeVehicleType.setting
    : { enableReceiverCash: false, isCashBySenderActive: false },

  defaultsAmount: state.SharedData.WebSettings ? state.SharedData.WebSettings.predefinedPaymentValues : null,
  currencyCode: state.SharedData.WebSettings ? state.SharedData.WebSettings.currencyCode : null,
  payment: state.SharedData.WebSettings ? state.SharedData.WebSettings.payment || [] : [],
  isInvoiceActive: state.SharedData.WebSettings ? state.SharedData.WebSettings.isInvoiceActive : null,


  cardInfo: state.NewOrder.newOrder.paymentMethod.cardInfo,
  paymentMethodId: state.NewOrder.newOrder.paymentMethod.paymentMethodId,

  active_service: state.NewOrder.newOrder.service.activeVehicleType,
  service_options: state.NewOrder.newOrder.service.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  driver_options: state.NewOrder.newOrder.driver.options,
  NRdropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff,
  NRotherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  NRpickupAddress: state.NewOrder.newOrder.pickup,
  minimumBalance:state.NewOrder.newOrder.balance.minimumBalance,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
