import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";

import removeD from "../../../../static/icon-ic-close-bold.svg";
import setD from "../../../../static/iconly-bold-tick-square.svg";
import removeAd from "../../../../static/icon-link-copy-3.svg";
import editAd from "../../../../static/icon-ic-edit.svg";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  get_customerPannel_type,
  Get_Permisssion,
} from "../../../../helper/UserData/userdate";
import { Api_Delete_Addressbook_AGW } from "../../../../helper/api/ApiGateway";
import {
  RemoveAddressBookFromList,
  RemoveDefaultAddressBookFromList,
  SetDefaultAddressBookFromList,
  SetDetailsEdit,
} from "../../../../Redux/actions/actionNames";
import { Customer_Set_Default_Addressbook_Action } from "../../../../Redux/actions/actions";
import AddressbookItemAddressSvg from "../../../../components/svg/addressbookItemAddressSvg";
import AddressbookItemNoteSvg from "../../../../components/svg/addressbookItemNoteSvg";
import AddressbookItemPhoneSvg from "../../../../components/svg/addressbookItemPhoneSvg";
import AddressbookItemNote2Svg from "../../../../components/svg/addressbookItemNote2Svg";
import AddressbookItemEmailSvg from "../../../../components/svg/addressbookItemEmailSvg";
import AddressbookSetDefaultSvg from "../../../../components/svg/addressbookSetDefaultSvg";

var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.anchorEl = React.createRef();
    this.RemoveDefaultAddressBook = this.RemoveDefaultAddressBook.bind(this);
    this.SetDefaultAddressBook = this.SetDefaultAddressBook.bind(this);
  }

  showPoper = () => {
    this.setState({ showpoper: true });
  };

  getBlockFloorRoom = () => {
    if (
      this.props.AddressData.buildingBlock.length > 0 ||
      this.props.AddressData.room.length > 0 ||
      this.props.AddressData.floor.length > 0
    ) {
      let arr = [];
      this.props.AddressData.buildingBlock.length > 0 &&
        arr.push(this.props.AddressData.buildingBlock);
      this.props.AddressData.room.length > 0 &&
        arr.push(this.props.AddressData.room);
      this.props.AddressData.floor.length > 0 &&
        arr.push(this.props.AddressData.floor);

      return arr.join(" , ");
    } else {
      return false;
    }
  };

  getNamePhone = () => {
    if (
      this.props.AddressData.phone.length > 0 ||
      this.props.AddressData.fullName.length > 0
    ) {
      let arr = [];

      this.props.AddressData.phone.length > 0 &&
        arr.push(this.props.AddressData.phone);
      this.props.AddressData.fullName.length > 0 &&
        arr.push(this.props.AddressData.fullName);

      return arr.join(" , ");
    } else {
      return false;
    }
  };


  getEmail = () => {
    if (
      this.props.AddressData.email.length > 0 
    ) {
      return this.props.AddressData.email
    } else {
      return false;
    }
  };


  

  getNote = () => {
    if (
      this.props.AddressData.description.length > 0 
    ) {
      return this.props.AddressData.description
    } else {
      return false;
    }
  };


  RemoveAddressBook = () => {
    this.setState({ showpoper: false });
    Api_Delete_Addressbook_AGW(
      this.props.dispatch,
      this.props.AddressData.id,
      (call) => {
        call &&
          this.props.dispatch(
            RemoveAddressBookFromList(this.props.AddressData.id)
          );
      }
    );
  };

  RemoveDefaultAddressBook = () => {
    this.setState({ showpoper: false });

    this.props.dispatch(
      Customer_Set_Default_Addressbook_Action(
        this.props.dispatch,
        { id: this.props.AddressData.id, isDefaultPickupAddress: false },
        (call) => {
          call &&
            this.props.dispatch(
              RemoveDefaultAddressBookFromList(this.props.AddressData.id)
            );
        }
      )
    );
  };

  SetDefaultAddressBook = () => {
    this.setState({ showpoper: false });

    this.props.dispatch(
      Customer_Set_Default_Addressbook_Action(
        this.props.dispatch,
        { id: this.props.AddressData.id, isDefaultPickupAddress: true },
        (call) => {
          call &&
            this.props.dispatch(
              SetDefaultAddressBookFromList(this.props.AddressData.id)
            );
        }
      )
    );
  };

  SetDeatailsINEDIT = () => {
    let phone = this.props.AddressData.phone;
    if(phone && phone.length > 2 && phone.substring(0, 2) == "00"){
      phone = "+"+phone.substring(2, phone.length)
    }

    let data ={
        ...this.props.AddressData ,
        phone
    }

    this.props.dispatch(SetDetailsEdit(data));
    this.props.onEdit();
  };

  render() {
    return (
      <div className="addressItemMenuContainer" id={this.props.AddressData.id}>
        <span className="admcT"> {this.props.AddressData.title}</span>
        {this.props.AddressData.isDefaultPickupAddress && (
            <span className="admcDFP"> Default pickup</span>
          )}
          <span className="admcMM" onClick={this.showPoper} ref={this.anchorEl}>
            {" "}
            ...
          </span>
        <div className="admcI">
          <AddressbookItemAddressSvg className="img add1" />
          <span> {this.props.AddressData.address}</span>
        </div>

        {this.getBlockFloorRoom() && (
          <div className="admcI">
            <AddressbookItemNoteSvg className="img add2" />
            {/* <img src={add2} /> */}
            <span> {this.getBlockFloorRoom()}</span>
          </div>
        )}

        {this.getNamePhone() && (
          <div className="admcI">
            <AddressbookItemPhoneSvg className="img add3" />
            <span> {this.getNamePhone()}</span>
          </div>
        )}

        {this.getNote() && (
          <div className="admcI">
            <AddressbookItemNote2Svg className="img add4" />
            <span> {this.getNote()}</span>
          </div>
        )}

      {this.getEmail() && (
          <div className="admcI">
            <AddressbookItemEmailSvg  className="img add5"/>
            {/* <img src={email} /> */}
            <span> {this.getEmail()}</span>
          </div>
        )}

        <Popper
          open={this.state.showpoper}
          anchorEl={this.anchorEl.current}
          role={undefined}
          style={{
            width: "160px",
            marginBottom: "4px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "10px",
            // position: "absolute",
            // left: "17px",
            backgroundColor: "white",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
          }}
          className={"PopperAddressbookMenuContainer"}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Paper>
              <ClickAwayListener
                onClickAway={(e) => {
                  this.setState({ showpoper: false });
                }}
              >
                <div autoFocusItem={this.state.activestop} id="menu-list-grow">
                  {Get_Permisssion()?.isDefaultPickupAddressEditable 
                     && (
                      <React.Fragment>
                        {!this.props.AddressData.isDefaultPickupAddress && (
                             <thdotItem onClick={this.SetDefaultAddressBook}>
                             <AddressbookSetDefaultSvg className="img xusoiwwjhq" />
                             {/* <img src={setD} />{" "} */}
                             <span className="spanblue" style={{color: global.config.colors.secondaryonlight}}>
                               {" "}
                               <Trans i18nKey={"Setdefaultpickup"}> </Trans>{" "}
                             </span>
                           </thdotItem>
                        )}

                        {this.props.AddressData.isDefaultPickupAddress && (
                          <thdotItem onClick={this.RemoveDefaultAddressBook}>
                            {" "}
                            <img src={removeD} />{" "}
                            <span className="spanRED">
                              {" "}
                              <Trans i18nKey={"Removedefault"}> </Trans>{" "}
                            </span>
                          </thdotItem>
                        )}
                      </React.Fragment>
                    )}{" "}

                

                 {  !this.props.AddressData.isDefaultPickupAddress &&

                 <thdotItem onClick={this.RemoveAddressBook}>
                    {" "}
                    <img src={removeAd} />{" "}
                    <span>
                      {" "}
                      <Trans i18nKey={"Remove"}> </Trans>{" "}
                    </span>
                  </thdotItem>}
                  <thdotItem onClick={this.SetDeatailsINEDIT}>
                    {" "}
                    <img src={editAd} />{" "}
                    <span>
                      {" "}
                      <Trans i18nKey={"Edit"}> </Trans>{" "}
                    </span>
                  </thdotItem>
                </div>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
