export const GreekLabel = {
  Cutomerpanel: 'Πάνελ πελάτη',
  login: 'Σύνδεση',
  Register: 'Εγγραφή',
  en: 'Αγγλικά',
  fa: 'Περσικά',
  Person: 'Ιδιώτης',
  Business: 'Επιχείρηση',
  Phonenumber: 'Αριθμός τηλεφώνου',
  Continue: 'Συνέχεια',
  phonenumberhasnotregisterd: 'Ο αριθμός τηλεφώνου δεν είναι καταχωρημένος',
  Username: 'Όνομα χρήστη',
  title: 'Τίτλος',
  Password: 'Κωδικός',
  ExOnro: 'π.χ. DeliveryBooking',
  Exstart: 'Π.χ. ******',
  googleConnected: 'Συνδεδεμένος με Google',
  facebookConnected: 'Συνδεδεμένο με Facebook',
  appleConnected: 'Συνδεδεμένο με Apple ID',
  plsenterphonnumber: 'Παρακαλώ εισάγετε τηλέφωνο',
  businessNamePlaceholder: 'π.χ. DeliveryBooking',
  registerBusinessNameLabel: 'Όνομα επιχείρησης',
  firstnnameloginplaceholder: 'Π.χ. Γιάννης',
  lastnameloginplaceholder: 'Π.χ. Δούκας',
  Firstname: 'Όνομα',
  Lastname: 'Επώνυμο',
  email: 'Email',
  emailPlaceholder: '(προαιρετικά) Π.χ. info@deliverybooking.gr',
  comingsoon: 'Έρχεται σύντομα',
  comingsoondesc: 'Για να δημιουργήσετε λογαριασμό επιχείρησης, επικοινωνήστε μαζί μας',
  CustomerAlreadyRegistered: 'Ο πελάτης είναι ήδη εγγεγραμμένος, παρακαλώ συνδεθείτε',
  registerCompleted: 'Εισαγάγετε τον κωδικό επαλήθευσης',
  googletokenerror: 'Πρόβλημα με το διακριτικό Google',
  facebooktokenerror: 'Πρόβλημα με το διακριτικό Facebook',
  appletokenerror: 'Πρόβλημα με το διακριτικό Apple',
  CustomerNotRegistered: 'Ο πελάτης δεν είναι εγγεγραμμένος',
  fr: 'Γαλλικά',
  de: 'Γερμανικά',
  InvalidVerificationCode: 'Μη έγκυρος κωδικός επαλήθευσης',
  a: 'Τα στοιχεία σας δεν βρέθηκαν',
  welcome: 'Καλώς ήρθατε',
  SmsLimitationError: 'Παρακαλώ δοκιμάστε ξανά σε λίγη ώρα',
  resendCodeWithTime: 'Επαναποστολή κωδικού (xxx)',
  resendCode: 'Επαναποστολή κωδικού',
  VerificationcodePlaceHolder: 'Π.χ. 1234',
  Verificationcode: 'Κωδικός επαλήθευσης',
  VerificationcodeText: 'Εισάγετε τον κωδικό που στάλθηκε στο xxx',
  VerificationCodeExpired: 'Ο κωδικός επαλήθευσης έχει λήξει',
  Pending: 'Εκκρεμεί',
  Unassigned: 'Μη ανατεθειμένη',
  Assigned: 'Ανατέθηκε',
  'Em curso': 'Im Laufen',
  PickedUp: 'Παραλήφθηκε',
  Started: 'Ξεκίνησε',
  statusInProgress: 'Σε εξέλιξη',
  Done: 'Ολοκληρώθηκε',
  CustomerCanceled: 'Ακύρωση (πελάτη)',
  DriverCanceled: 'Ακύρωση (οδηγού)',
  SupportCanceled: 'Ακύρωση (διαχειριστή)',
  Arrived: 'Έφτασε',
  new: 'Νέα',
  Historyofyourorderswillbehere: 'Το ιστορικό των παραγγελιών σας θα εμφανιστεί εδώ',
  otherdropoffs: 'Άλλα σημεία παράδοσης',
  Orderhistory: 'Ιστορικό παραγγελιών',
  Noorder: 'Καμία παραγγελία',
  loadingList: 'Περιμένετε λίγο για τη λήψη πληροφοριών',
  loadingError: 'Πρόβλημα στη λήψη πληροφοριών',
  retry: 'Επανάληψη δοκιμής',
  Create_your_first_requst: 'Δημιουργία νέας παραγγελίας',
  List_of_you_active_orders_will_be_here: 'Η λίστα των ενεργών παραγγελιών σας θα εμφανιστεί εδώ',
  Aboutus: 'Σχετικά με εμάς',
  Readour: 'Διαβάστε το',
  privaypolicy: 'Πολιτική απορρήτου',
  Transactions: 'Συναλλαγές',
  NoTransaction: 'Δεν έχετε συναλλαγή',
  Historyofyourransactionswillbehere: 'Το ιστορικό των συναλλαγών σας θα εμφανιστεί εδώ',
  Settings: 'Ρυθμίσεις',
  Language: 'Γλώσσα',
  English: 'Αγγλικά',
  Gereman: 'Περσικά',
  French: 'Γαλλικά',
  Logout: 'Αποσύνδεση',
  logotTitle: 'Αποσύνδεση από τον λογαριασμό;',
  logoutDescription: 'Θα αποσυνδεθείτε από τον λογαριασμό και θα πρέπει να συνδεθείτε ξανά αργότερα',
  confirmLogout: 'Ναι, αποσύνδεση',
  skipLogout: 'Ακύρωση',
  savesettings: 'Αποθήκευση ρυθμίσεων',
  emailInvalid: 'Το email δεν είναι έγκυρο',
  Neworder: 'Νέα παραγγελία',
  Service: 'Υπηρεσία',
  poperLoading: 'Παρακαλώ περιμένετε',
  Pickup: 'Παραλαβή',
  SetPickup: 'Ορίστε παραλαβή',
  ChooseoOnMap: 'Επιλογή στον χάρτη',
  Fornow: 'Προς το παρόν',
  Scheduled: 'Προγραμματισμένη',
  before: 'Πριν:',
  after: 'Μετά:',
  confirm: 'Επιβεβαίωση',
  Settime: 'Ορισμός ώρας',
  Cancel: 'Ακύρωση',
  Pleasetryanotherkeyword: 'Δοκιμάστε άλλη λέξη-κλειδί',
  Noinformationfound: 'Δεν βρέθηκαν πληροφορίες',
  address: 'Διεύθυνση',
  block: 'Οικοδομικό τετράγωνο',
  Floor: 'Όροφος',
  Unit: 'Διαμέρισμα',
  senderfullname: 'Όνομα αποστολέα',
  senderphonenumber: 'Αριθμός αποστολέα',
  notesfordriver: 'Σημειώσεις για τον οδηγό',
  DropOff: 'Παράδοση',
  SetDropOff: 'Ορισμός παράδοσης',
  fetchingdata: 'Λήψη πληροφοριών...',
  Recieverfullname: 'Όνομα παραλήπτη',
  Recieverphonenumber: 'Αριθμός παραλήπτη',
  PromotionCode: 'Κωδικός προσφοράς',
  Delete: 'Διαγραφή',
  Save: 'Αποθήκευση',
  PromoCodeValid: 'Ο κωδικός προσφοράς αποθηκεύτηκε',
  SenderWallet: 'Πορτοφόλι',
  SenderCash: 'Μετρητά',
  ReceiverCash: 'Πληρωμή από τον παραλήπτη',
  lowBalance: 'Χαμηλό υπόλοιπο',
  Balance: 'Πορτοφόλι',
  Methods: 'Μέθοδοι',
  balanceError: 'Το υπόλοιπό σας πρέπει να είναι τουλάχιστον ίσο με την τιμή της παραγγελίας',
  SendOrder: 'Αποστολή παραγγελίας',
  feedback: 'Σχόλια',
  Feedback: 'Σχόλια',
  Orderagain: 'Επανάληψη παραγγελίας',
  Createdsuccessfully: 'Δημιουργήθηκε με επιτυχία',
  chooseAmount: 'Επιλογή ποσού',
  orenterthepreferedamount: 'Επιθυμητό ποσό:',
  enteramount: 'Εκτίμηση κόστους',
  pay: 'Πληρωμή',
  selectPaymentGateway: 'Επιλέξτε τρόπο πληρωμής',
  Time_MachineText: 'Θέλετε να ανακτήσετε τις πληροφορίες της παραγγελίας σας;',
  yes: 'Ναι',
  ignore: 'Αγνόηση',
  Order: 'Παραγγελία',
  statusPending: 'Εκκρεμεί',
  statusUnassigned: 'Μη ανατεθειμένη',
  statusAssigned: 'Ανατέθηκε',
  statusStarted: 'Ξεκίνησε',
  statusPickedUp: 'Παραλήφθηκε',
  statusDone: 'Ολοκληρώθηκε',
  statusCustomerCanceled: 'Ακύρωση (πελάτη)',
  statusDriverCanceled: 'Ακύρωση (οδηγού)',
  statusArrived: 'Έφτασε',
  statusSupportCanceled: 'Ακύρωση (διαχειριστή)',
  statusCanceled: 'Ακυρώθηκε',
  Phone: 'Τηλέφωνο',
  additonalservice: 'Επιπρόσθετη υπηρεσία',
  CallDriver: 'Κλήση οδηγού',
  Message: 'Μήνυμα',
  orderInfoError: 'Σφάλμα κατά τη λήψη πληροφοριών',
  support: 'Υποστήριξη',
  map: 'Χάρτης',
  receipt: 'Απόδειξη',
  photo: 'Φωτογραφία',
  ServicePhoto: 'Φωτογραφία υπηρεσίας',
  Addphoto: 'Προσθήκη φωτογραφίας',
  Estime: 'Εκτ. χρόνος',
  Espath: 'Εκτ. διαδρομή',
  pod: 'Απόδειξη παράδοσης',
  Notes: 'Σημειώσεις',
  Photo: 'Φωτογραφία',
  Signature: 'Υπογραφή',
  Nonotes: 'Χωρίς σημειώσεις',
  Nosignature: 'Χωρίς υπογραφή',
  Nophoto: 'Χωρίς Φωτογραφία',
  Price: 'Κόστος',
  Receiver: 'Από τον παραλήπτη',
  Sender: 'Από τον αποστολέα',
  Cash: 'Μετρητά',
  Canceltravel: 'Αίτημα ακύρωσης',
  CancellText: 'Μετά την ακύρωση, η παραγγελία ακυρώνεται για τον οδηγό',
  Estimated: 'Εκτιμώμενο',
  Wallet: 'Υπόλοιπο',
  Copylink: 'Αντιγραφή συνδέσμου',
  repeatOrder: 'Επανάληψη παραγγελίας',
  Edit: 'Επεξεργασία',
  confirmCancel: 'Ναι',
  paymentmethod: 'Τρόπος Πληρωμής',
  SucceessFul: 'Ολοκληρώθηκε με επιτυχία',
  errorinServer: 'Πρόβλημα διακομιστή',
  Comment: 'Σχόλιο',
  Exfeedbackinput: 'Π.χ. παρέδωσε πολύ γρήγορα',
  Wallett: 'Πορτοφόλι',
  TypeHere: 'Πληκτρολογήστε εδώ',
  Loadingmessages: 'Φόρτωση μηνυμάτων',
  PoweredBy: 'Powered By DeliveryBooking',
  newincomemessage: 'Λάβατε νέο μήνυμα',
  newincomemessageanother: 'Έχετε νέο μήνυμα σε άλλη παραγγελία',
  youhaveunreadmessage: 'Μη αναγνωσμένο μήνυμα',
  Vehicletype: 'Τύπος οχήματος',
  Pickup2: 'Παραλαβή',
  Dropoff2: 'Παράδοση',
  Events: 'Συμβάντα',
  allEvents: 'Όλα τα συμβάντα',
  star: 'Αστέρια',
  Yourrate: 'Η βαθμολογία σας',
  CopyOrderlink: 'Αντιγραφή συνδέσμου παραγγελίας',
  CopyPickuplink: 'Αντιγραφή συνδέσμου παραλαβής',
  CopyDeliverylink: 'Αντιγραφή συνδέσμου παράδοσης',
  Nointernetconnection: 'Δεν υπάρχει σύνδεση στο διαδίκτυο',
  close: 'Κλείσιμο',
  seen: 'Διαβάστηκε',
  controlThatbeforeLoweEqualAfter: 'Η ώρα ""Πριν:"" δεν μπορεί να είναι νωρίτερα από την ώρα ""Μετά:"".',
  controlThatbeforeAndAfterMustBiggerthanCurrentDate: 'Οι ώρες ""Πριν:"" και ""Μετά:"" δεν μπορούν να είναι νωρίτερα από την τρέχουσα ώρα.',
  errorInPanelSetting: 'Σφάλμα στις ρυθμίσεις κράτησης, παρακαλώ επικοινωνήστε με την υποστήριξη',
  Arabic: 'Αραβικά',
  ar: 'Αραβικά',
  veeDeliveryLoginText1: 'Στείλτε Οτιδήποτε',
  veeDeliveryLoginText2: 'Σε Οποιονδήποτε, ΤΩΡΑ!',
  Schedule: 'Προγραμματείστε',
  Date: 'Ημερομηνία',
  Time: 'Ώρα',
  noDateToShow: 'Δεν υπάρχει διαθέσιμη ημερομηνία προς εμφάνιση',
  noTimeToShow: 'Δεν υπάρχει διαθέσιμη ώρα προς εμφάνιση',
  Apply: 'Εφαρμογή',
  Increaseyourbalance: 'Αύξηση υπολοίπου',
  AddBalance: 'Προσθήκη υπολοίπου',
  Addressbook: 'Βιβλίο διευθύνσεων',
  AddressbookSearchbar: 'Αναζήτηση με διεύθυνση, τίτλο και κωδικό',
  Removedefault: 'Αφαίρεση Προεπιλογής',
  Setdefaultpickup: 'Ορισμός Προεπιλογής',
  Remove: 'Αφαίρεση',
  newAddress: 'Νέα διεύθυνση',
  NewAddressbookInfoTitle: 'Πληροφορίες',
  Addressdetails: 'Λεπτομέρειες διεύθυνσης',
  Title: 'Τίτλος',
  Code: 'Κωδικός',
  editAddress: 'Επεξεργασία διεύθυνσης',
  AddressbookDropOf: 'Βιβλίο διευθύνσεων (Παράδοση)',
  AddressbookPickup: 'Βιβλίο διευθύνσεων (Παραλαβή)',
  hide: 'Απόκρυψη',
  Nosavedaddress: 'Χωρίς αποθηκευμένη διεύθυνση',
  NosavedaddressDesc: 'Μπορείτε να δημιουργήσετε μία, κάνοντας κλικ στο εικονίδιο σελιδοδείκτη δίπλα στη διεύθυνση ή πηγαίνοντας στη σελίδα βιβλίου διευθύνσεων.',
  Romanian: 'Ρουμανικά',
  ro: 'Ρουμανικά',
  Dutch: 'Ολλανδικά',
  Shipmentlabel: 'Ετικέτα αποστολής',
  Name: 'Όνομα',
  CashOnDelivery: 'Αντικαταβολή',
  EnterAmount: 'Εισαγάγετε ποσό',
  COD: 'Αντικαταβολή',
  Surchargexxx: 'Επιβάρυνση xxx',
  Pricedetails: 'Λεπτομέρειες τιμής',
  minxxx: 'Ελάχιστο xxx ',
  toPickup: 'Προς Παραλαβή',
  toDropOff: 'Προς Παράδοση',
  AddCreditCard: 'Προσθήκη κάρτας',
  CustomerWallet: 'Πορτοφόλι πελάτη',
  Add: 'Προσθήκη',
  Addedsuccessfully: 'Προστέθηκε με επιτυχία',
  paymentmethods: 'Τρόποι πληρωμής',
  NoCards: 'Δεν υπάρχουν κάρτες',
  Listofyourcardswillbehere: 'Η λίστα των καρτών σας θα εμφανιστεί εδώ',
  CardInfo: 'Πληροφορίες κάρτας',
  ExpireDate: 'Ημερομηνία λήξης',
  CVC: 'CVC',
  PostalCode: 'Ταχυδρομικός κωδικός',
  RemoveCardq: 'Αφαίρεση κάρτας;',
  RemoveCardA: 'Η κάρτα θα αφαιρεθεί και δεν θα μπορεί να χρησιμοποιηθεί ξανά',
  YesRemove: 'Ναι, Αφαίρεση',
  Cardisinuse: 'Η κάρτα χρησιμοποιείται',
  ok: 'OK',
  Pleasetypeyourwordstosearch: 'Πληκτρολογήστε λέξη για αναζήτηση',
  spanish: 'Ισπανικά',
  es: 'Ισπανικά',
  Invoices: 'Τιμολόγια',
  noInvoice: 'Αρ. xxx',
  germany: 'Γερμανικά',
  nl: 'Ολλανδικά',
  downloadClientApplication: 'Κατεβάστε την εφαρμογή xxx',
  AssignedAt: 'Ανάθεση έως xxx',
  StartedAt: 'Έναρξη έως xxx',
  ArrivedAt: 'Άφιξη έως xxx',
  PickedUpAt: 'Παραλαβή στις xxx',
  DoneAt: 'Ολοκλήρωση σε xxx',
  Russian: 'Ρωσικά',
  Azerbaijani: 'Αζέρικα',
  rus: 'Ρωσικά',
  aze: 'Αζέρικα',
  Support: 'Υποστήριξη',
  SendRequest: 'Αποστολή αιτήματος',
  CallTheSupport: 'Κλήση υποστήριξης',
  Choosethesubject: 'Επιλογή Θέματος',
  Seefrequentquestions: 'Δείτε τις συχνές ερωτήσεις',
  Details: 'Λεπτομέρειες',
  Send: 'Αποστολή',
  MessageBox: 'Θυρίδα μηνυμάτων',
  NoMessages: 'Δεν υπάρχουν μηνύματα',
  Yourmessageswillbehere: 'Τα μηνύματά σας θα εμφανίζονται εδώ',
  Portuguese: 'Πορτογαλικά',
  pt: 'Πορτογαλικά',
  Greek: 'Ελληνικά',
  el: 'Ελληνικά',
  individual: 'Ιδιώτης',
  comingsoondesc2: 'Για τη δημιουργία επαγγελματικού λογαριασμού, παρακαλώ επικοινωνήστε μαζί μας',
  contactUs: 'Επικοινωνία',
  changePassword: 'Αλλαγή κωδικού',
  changePasswordp1: 'Θα αποσυνδεθείτε από όλες τις συνεδρίες, εκτός από αυτήν, για να προστατέψετε τον λογαριασμό σας από μη εξουσιοδοτημένη πρόσβαση',
  changePasswordp2: 'Ο κωδικός πρόσβασής πρέπει να αποτελείται από τουλάχιστον 6 χαρακτήρες',
  currentPassword: 'Τρέχων κωδικός',
  newpassword: 'Νέος κωδικός ',
  Retypenewpassword: 'Εισαγάγετε ξανά τον νέο κωδικό',
  account: 'Λογαριασμός (πορτοφόλι)',
  Required: 'Προαπαιτούμενο',
  registerBussinessUsername: 'Όνομα χρήστη (τουλάχιστον 5 χαρακτήρες)',
  registerBussinessPassword: 'Κωδικός πρόσβασης (τουλάχιστον 6 χαρακτήρες)',
  ReferenceID: 'Αναγνωριστικό αναφοράς',
  EnterId: 'Εισαγάγετε αναγνωριστικό',
  Orderid: 'ID παραγγελίας',
  EnterCode: 'Εισαγάγετε κωδικό',
  AustrianGerman: 'Αυστριακά Γερμανικά',
  deu: 'Αυστριακά Γερμανικά',
  pop: 'Απόδειξη Παραλαβής',
  Options: 'Επιλογές',
  RequiredText: 'Υποχρεωτικό',
  PleaseSelect: 'Παρακαλώ Επιλέξτε',
  Optimizedropoffs: 'Βελτιστοποίηση Παραδόσεων',
  'Optimizedropoffs?': 'Βελτιστοποίηση Παραδόσεων;',
  OptimizedropoffsDesc: 'Η σειρά παράδοσης θα βελτιστοποιηθεί και μπορεί να αλλάξει η τιμή.',
  Optimize: 'Βελτιστοποίηση',
  zh: 'Απλοποιημένα Κινέζικα',
  ChineseSimplified: 'Απλοποιημένα Κινέζικα',
  Bulkimport: 'Μαζική Εισαγωγή',
  processing: 'Επεξεργασία',
  done: 'Ολοκληρώθηκε',
  failed: 'Απέτυχε',
  Draft: 'Πρόχειρο',
  Uploading: 'Μεταφόρτωση',
  Draganddropyourfile: 'Σύρετε και αποθέστε το αρχείο σας',
  Onlycsvformatissupported: 'Υποστηρίζεται μόνο η μορφή .csv',
  ORyoucan: 'Ή μπορείτε',
  Openfile: 'Άνοιγμα αρχείου',
  Noimportsyet: 'Δεν υπάρχουν εισαγωγές ακόμα',
  NoimportsyetDesc: 'Δείτε τις εισαγωγές σας και ελέγξτε την κατάστασή τους.',
  nosupportdialogeTitle: 'Η μορφή αρχείου δεν υποστηρίζεται',
  nosupportdialogeText: 'Το αρχείο xxx δεν υποστηρίζεται. Επιτρέπονται μόνο αρχεία .csv με πληροφορίες παραγγελίας.',
  Import: 'Εισαγωγή',
  previewTitle: 'Προεπισκόπηση και Επιλογή',
  previewDesc: 'Ελέγξτε αν το πρώτο στοιχείο του αρχείου σας έχει σωστή δομή.',
  Upload: 'Μεταφόρτωση',
  ServiceandOrdertype: 'Τύπος Υπηρεσίας και Παραγγελίας',
  Fileisproccessing: 'Το αρχείο επεξεργάζεται...',
  proccessingDesc: 'Μπορείτε να ανανεώσετε για να δείτε αν είναι έτοιμο το αποτέλεσμα',
  serverError: 'Σφάλμα Διακομιστή',
  isRequired: 'Το xxx είναι υποχρεωτικό',
  Result: 'Αποτέλεσμα',
  ResultText: 'Δείτε τα προβλήματα και κατεβάστε τις γραμμές για διόρθωση.',
  ErrorDetails: 'Παραγγελίες με σφάλμα',
  ErrorDetailsText: 'Οι παραγγελίες με σφάλματα παρατίθενται στο αρχείο με λεπτομέρειες.',
  DownloadErroredRows: 'Κατεβάστε τις γραμμές με σφάλματα',
  importedsuccessfully: 'xxx παραγγελίες εισήχθησαν με επιτυχία',
  ordershaserrors: 'xxx παραγγελίες έχουν σφάλματα',
  refresh: 'Ανανέωση',
  pleaseSellectParsel: 'Παρακαλώ Επιλέξτε Υπηρεσία',
  Ondemand: "Κατ' Απαίτηση",
  PickupandDelivery: 'Παραλαβή και Παράδοση',
  PickupDelivery: 'Παραλαβή και Παράδοση',
  Delivery: 'Παράδοση',
  tab_Delivery: 'Παράδοση',
  tab_Ondemand: "Κατ' Απαίτηση",
  tab_Pickup: 'Παραλαβή',
  tab_PickupDelivery: 'Παραλαβή και Παράδοση',
  Downloadsamplecsv: 'Κατεβάστε δείγμα CSV',
  Distance: 'Απόσταση',
  Duration: 'Διάρκεια',
  driverPhoto: 'Φωτογραφία Οδηγού',
  yourPhoto: 'Η Φωτογραφία σας',
  No: 'Όχι',
  confirmCancel2: 'Ναι, Ακύρωση Παραγγελίας',
  UploadPhoto: 'Μεταφόρτωση Φωτογραφίας',
  Photos: 'Φωτογραφίες',
  ParcelPhoto: 'Φωτογραφία Δέματος',
  ProofofPickup: 'Απόδειξη Παραλαβής',
  EditPhoto: 'Επεξεργασία Φωτογραφίας',
  TrackLink: 'Σύνδεσμος Εντοπισμού',
  ShipmentLabel: 'Ετικέτα Αποστολής',
  Receipt: 'Απόδειξη',
  Confirm: 'Επιβεβαίωση',
  BulkEditStatusSummary: 'Σύνοψη Μαζικής Επεξεργασίας Κατάστασης',
  Successful: 'Επιτυχής',
  Failed: 'Αποτυχημένη',
  OrderID: 'Αναγνωριστικό Παραγγελίας',
  New: 'Νέα',
  Clear: 'Εκκαθάριση',
  Noresultfound: 'Δεν βρέθηκαν αποτελέσματα',
  Typeyourkeyword: 'Πληκτρολογήστε τη λέξη-κλειδί',
  Typeintheinputtostartsearch: 'Γράψτε στο πεδίο για να ξεκινήσετε αναζήτηση.',
  OnDemand: "Κατ' Απαίτηση",
  P_Hub_D: 'P_Hub_D',
  AddressDetails: 'Λεπτομέρειες Διεύθυνσης',
  Map: 'Χάρτης',
  'BulkEditStatusxxx/yyy': 'Μαζική Επεξεργασία Κατάστασης xxx/yyy',
  'xxxSuccessfull,yyyFailed': 'xxx Επιτυχής, yyy Αποτυχημένες',
  xxxfromyyy: 'xxx από yyy',
  xxxrows: 'xxx γραμμές',
  xxxselected: 'xxx επιλεγμένα',
  xxxdropoffs: 'xxx Παραδόσεις',
  'Status Updated at xxx': 'Η Κατάσταση Ενημερώθηκε στις xxx',
  More: 'Περισσότερα',
  Summary: 'Σύνοψη',
  Prev: 'Προηγούμενο',
  Next: 'Επόμενο',
  ClearAll: 'Εκκαθάριση Όλων',
  Errorinloadingdata: 'Σφάλμα στη Φόρτωση Δεδομένων',
  Therewasaproblemloadingdatapleasetryagainorcontactsupport: 'Πρόβλημα στη φόρτωση δεδομένων. Δοκιμάστε ξανά ή καλέστε υποστήριξη.',
  Tryagain: 'Δοκιμάστε ξανά',
  ConfigTable: 'Πίνακας Ρυθμίσεων',
  ActiveColumns: 'Ενεργές Στήλες',
  Createyourorder: 'Δημιουργήστε την παραγγελία σας',
  Yourorderswillbeshownhere: 'Οι παραγγελίες σας θα εμφανίζονται εδώ',
  Pleasecheckyourfiltersorkeyword: 'Ελέγξτε τα φίλτρα ή τη λέξη-κλειδί σας',
  CustomerPhoto: 'Φωτογραφία Πελάτη',
  NoAttachments: 'Χωρίς Συνημμένα',
  AllAttachmentswillbeshownhere: 'Όλα τα Συνημμένα θα εμφανίζονται εδώ',
  Scheduleoverlap: 'Η ώρα παραλαβής πρέπει να είναι πριν την παράδοση και χωρίς αλληλοκάλυψη.',
  SearchAddress: 'Αναζήτηση Διεύθυνσης',
  NoResultfromLocalDatabaseD: 'Ελέγξτε το ξανά ή δοκιμάστε άλλη λέξη-κλειδί',
  NoResultfromMapService: 'Δεν βρέθηκαν αποτελέσματα από την υπηρεσία χάρτη.',
  Senderinfo: 'Πληροφορίες Αποστολέα',
  Receiverinfo: 'Πληροφορίες Παραλήπτη',
  SchedulePickup: 'Προγραμματισμός Παραλαβής',
  ScheduleDelivery: 'Προγραμματισμός Παράδοσης',
  Fullname: 'Πλήρες Όνομα',
  addressDetail: 'Λεπτομέρειες Διεύθυνσης',
  Addtoaddressbook: 'Προσθήκη στο Βιβλίο Διευθύνσεων',
  ExHome: 'Π.χ. Σπίτι',
  Ex123: 'Π.χ. 123',
  Savechanges: 'Αποθήκευση Αλλαγών',
  Removefromaddressbook: 'Αφαίρεση από το Βιβλίο Διευθύνσεων;',
  RemovefromaddressbookDesc: 'Η διεύθυνση θα προστεθεί στο βιβλίο διευθύνσεων.',
  Clearfield: 'Εκκαθάριση Πεδίου',
  CreateOrder: 'Δημιουργία Παραγγελίας',
  Walletbalanceislow: 'Χαμηλό υπόλοιπο πορτοφολιού.',
  Walletbalanceislowd: 'Το υπόλοιπο πρέπει να είναι ίσο ή μεγαλύτερο από την τιμή παραγγελίας.',
  'Selected date and time': 'Επιλεγμένη ημερομηνία και ώρα',
  Vehicle: 'Όχημα',
  Other: 'Άλλο',
  'Fill in or Select': 'Συμπληρώστε ή Επιλέξτε',
  Fields: 'Πεδία',
  OrderType: 'Τύπος Παραγγελίας',
  Stage: 'Στάδιο',
  Status: 'Κατάσταση',
  CreatedAt: 'Δημιουργήθηκε στις',
  StatusUpdatedAt: 'Ενημέρωση κατάστασης στις',
  Note: 'Σημείωση',
  ReferenceId: 'Κωδικός Δέματος',
  PickupAddress: 'Διεύθυνση Παραλαβής',
  DeliveryAddress: 'Διεύθυνση Παράδοσης',
  EstimatedDistanceInMeters: 'Εκτιμώμενη Απόσταση (σε Μέτρα)',
  EstimatedDuration: 'Εκτιμώμενη Διάρκεια',
  'Payment Method': 'Τρόπος Πληρωμής',
  PickupAddressDetail: 'Λεπτομέρειες Διεύθυνσης Παραλαβής',
  PickupCompleteAfter: 'Ολοκλήρωση Παραλαβής Μετά',
  PickupcompleteBefore: 'Ολοκλήρωση Παραλαβής Πριν',
  PickupFullName: 'Όνομα Αποστολέα',
  PickupPhone: 'Τηλέφωνο Αποστολέα',
  PickupEmail: 'Email Αποστολέα',
  PickupPodNote: 'Σημείωση POD Παραλαβής',
  PickupPodPhoto: 'Φωτογραφία POD Παραλαβής',
  PickupPodSignature: 'Υπογραφή POD Παραλαβής',
  DeliveryAddressDetail: 'Λεπτομέρειες Διεύθυνσης Παράδοσης',
  DeliveryCompleteAfter: 'Ολοκλήρωση Παράδοσης Μετά',
  DeliverycompleteBefore: 'Ολοκλήρωση Παράδοσης Πριν',
  DeliveryFullName: 'Όνομα Παραλήπτη',
  DeliveryPhone: 'Τηλέφωνο Παραλήπτη',
  DeliveryEmail: 'Email Παραλήπτη',
  DeliveryPodNote: 'Σημείωση POD Παράδοσης',
  DeliveryPodPhoto: 'Φωτογραφία POD Παράδοσης',
  DeliveryPodSignature: 'Υπογραφή POD Παράδοσης',
  'Customer Email': 'Email Πελάτη',
  PaymentProvider: 'Πάροχος Πληρωμών',
  'Shipment Label': 'Ετικέτα Αποστολής',
  'Track Order': 'Παρακολούθηση Παραγγελίας',
  Stages: 'Στάδια',
  CodAmount: 'Ποσό Αντικαταβολής',
  selected: 'επιλεγμένα',
  from: 'από',
  rows: 'γραμμές',
  'Select Status': 'Επιλογή Κατάστασης',
  'Select Reason': 'Αιτιολογία',
  'Edit Status': 'Επεξεργασία Κατάστασης',
  Persian: 'Persian',
  fo: 'Fo',
  undefined: 'Service type',
  'Pickup Address': 'Pickup Address',
  'You must assign driver to select this route': 'You must assign driver to select this route',
  'You must choose an unassigned route or unassign selected route': 'You must choose an unassigned route or unassign selected route'
}