import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";

import {
  get_Id,
  get_currencyCode,
  get_lang,
  get_customerPannel_type,
} from "../../../../helper/UserData/userdate";
import { DetectOndemandOrReserve } from "../../../../helper/worker/DetectOndemandOrReserve";
import momentJalaali from "moment-jalaali";
import ChangeMethod from "./changeMethod";
import { Api_Create_Ondemand_Order_AGW, Api_Create_PickupDelivery_Order_AGW } from "../../../../helper/api/ApiGateway";
import Backdrop from "@material-ui/core/Backdrop";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import BottomSheet from 'bottom-sheet-react';
import iclowcredit from "../../../../static/ic-low-credit.png";
import Wallet from "../../Menu/wallet";
import { DetectOndemandOrPickupDelivery } from "../../../../helper/worker/DetectOndemandOrReserve copy";
import { v4 as uuidv4 } from 'uuid';
import ProgressIndicator from "../../../../design-system/lib/ProgressIndicator";
import Typography from "../../../../design-system/lib/Typography";
import IconProvider from "../../../../design-system/lib/IconProvider";
import {ReactComponent as   INFO_CIRCLE } from "../../../../static/IconProvider/info-circle.svg";
import {ReactComponent as Arrow_down_bold_white} from "../../../../static/IconProvider/arrow_down_bold_white.svg";

import InputFilter from "../../../../design-system/lib/InputFilter";
import PromotionCode from "./promotionCode";
import DropDownButton from "../../../../design-system/lib/DropDownButton";
import zIndex from "newmaterial-ui/styles/zIndex";
import styles from '../index.module.css';
import IconProviderSvgModifier from "../../../../design-system/lib/IconProviderSvgModifier";
import IconProviderSvgCustomColor from "../../../../design-system/lib/IconProviderSvgCustomColor";
import ConfirmButton from "./confirmButton";

var numeral = require("numeral");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorScheduleMinimumBalance: false,
      serverResponse: ""
    };
  }

  removeEmpty = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
  };

  handleScheduleMinimumBalance = (res) => {
    this.setState({
      showErrorScheduleMinimumBalance: true,
      serverResponse: res,
    });
  };



  handleCollapseScheduleMinimumBalance = () => {
    this.setState({
      showErrorScheduleMinimumBalance: false
    });
  };


  handleOpenWallet = () => {
    this.setState({
      showErrorScheduleMinimumBalance: false,
      OpenWallet: !this.state.OpenWallet
    });
  }


  CheckIsScheduled(data, callback) {
    try {
      var itemsProcessed = 0;
      var callbackk = false;
      data.forEach(element => {
        itemsProcessed++;

        if (!!element.scheduleDateAfter || !!element.scheduleDateBefore) {
          callbackk = true;
          itemsProcessed = data.length
          callback(true)
        } else if (itemsProcessed === data.length) {
          callback(callbackk)
        }


      });

    } catch (error) {
      callback(false)
    }
  }


  isPickupDelivery = () => {
    let typeso = (this.props.serviceactiveVehicleType?.orderTypes ? this.props.serviceactiveVehicleType?.orderTypes : [])

    if (typeso.indexOf("PickupDelivery") > -1) {
      alert("isPickupDelivery")

      return true
    } else {
      return true
    }

  }


  SendOrder = (draft) => {

    if (this.props.sendOrder.canSaveOrder && !this.props.sendOrder.loading) {
      let CheckIsScheduledVar = false

      this.CheckIsScheduled([{ ...this.props.pickup }, { ...this.props.firstDropOff }, ...this.props.otherdropOffs.map((item => {
        return { ...item }
      }))], (call) => {
        CheckIsScheduledVar = call
      })


      DetectOndemandOrPickupDelivery(
        this.props.serviceactiveVehicleType ? this.props.serviceactiveVehicleType.orderTypes : [],
        (call) => {



          if (call === "ondeman") {



            Api_Create_Ondemand_Order_AGW(
              this.props.dispatch,
              {
                codAmount: Number(this.props.codAmount),
                referenceId: this.props.referenceID,
                customerId: get_Id(),
                uid: uuidv4(),
                isScheduled: CheckIsScheduledVar,
                pickup: {
                  address: this.props.pickup.address,
                  coordinates: [
                    this.props.pickup.adresss_loc.lng,
                    this.props.pickup.adresss_loc.lat,
                  ],
                  buildingBlock: this.props.pickup.block,
                  description: this.props.pickup.noteForDriver,
                  floor: this.props.pickup.floor,
                  fullName: this.props.pickup.senderFullName,
                  phone: this.props.pickup.senderPhoneNumber,
                  placeId: "",
                  room: this.props.pickup.unit,
                  email: this.props.pickup.email,
                  schedulePickupNow: this.props.pickup.scheduleDateAfter ||
                    this.props.pickup.scheduleDateBefore
                    ? false
                    : (CheckIsScheduledVar ? true : false),
                  scheduleDateAfter: momentJalaali(
                    this.props.pickup.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                      Number(this.props.pickup.scheduleDateAfter + "000")
                    ).unix()
                    : 0,
                  scheduleDateBefore: momentJalaali(
                    this.props.pickup.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                      Number(this.props.pickup.scheduleDateBefore + "000")
                    ).unix()
                    : 0,
                },
                dropoffs: [
                  {
                    address: this.props.firstDropOff.address,
                    coordinates: [
                      this.props.firstDropOff.adresss_loc.lng,
                      this.props.firstDropOff.adresss_loc.lat,
                    ],
                    buildingBlock: this.props.firstDropOff.block,
                    floor: this.props.firstDropOff.floor,
                    fullName: this.props.firstDropOff.recieverFullName,
                    phone: this.props.firstDropOff.recieverPhoneNumber,
                    placeId: "",
                    room: this.props.firstDropOff.unit,
                    email: this.props.firstDropOff.email,
                    customerDescription: this.props.firstDropOff.noteForDriver,
                    scheduleDateAfter: momentJalaali(
                      this.props.firstDropOff.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                        Number(
                          this.props.firstDropOff.scheduleDateAfter + "000"
                        )
                      ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      this.props.firstDropOff.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                        Number(
                          this.props.firstDropOff.scheduleDateBefore + "000"
                        )
                      ).unix()
                      : 0,
                  },
                  ...this.props.otherdropOffs.map((drop) => {
                    return {
                      address: drop.address,
                      coordinates: [drop.adresss_loc.lng, drop.adresss_loc.lat],
                      buildingBlock: drop.block,
                      floor: drop.floor,
                      fullName: drop.recieverFullName,
                      phone: drop.recieverPhoneNumber,
                      placeId: "",
                      room: drop.unit,
                      email: drop.email,
                      customerDescription: drop.noteForDriver,
                      scheduleDateAfter: momentJalaali(
                        drop.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                          Number(drop.scheduleDateAfter + "000")
                        ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        drop.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                          Number(drop.scheduleDateBefore + "000")
                        ).unix()
                        : 0,
                    };
                  }),
                ],
                vehicleType: this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.id ? {
                  id:
                    this.props.auto_activeVehicleType &&
                    this.props.auto_activeVehicleType.id,
                  options: [
                    ...this.props.driveroptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "" };
                    }),
                  ],
                } : null,
                service: {
                  id: this.props.serviceactiveVehicleType.id,
                  options: [
                    ...this.props.serviceoptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "", inputValue: op.inputValue ? op.inputValue : null };
                    }),
                  ],
                },
                paymentMethod: this.props.paymentMethod,
                paymentSide: this.props.paymentSide,
                paymentProvider: this.props.paymentProvider,
                cardInfo: this.props.cardInfo,
                paymentMethodId: this.props.paymentMethodId,
                promoCode: this.props.promoCodeValid
                  ? this.props.promoCode
                  : "",
                // "paymentMethodId": null,
                // "paymentProvider": null,
                // "cardInfo": null,
              },
              {
                service: {
                  icon: this.props.serviceactiveVehicleType.icon,
                  title: this.props.serviceactiveVehicleType.title,
                },
                vehicletype: this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.id ? {
                  icon: this.props.auto_activeVehicleType.icon,
                  title: this.props.auto_activeVehicleType.title,
                } : null,
              },
              (serverRes) => {
                this.handleScheduleMinimumBalance(serverRes);
              }
            );
          } else {
            Api_Create_PickupDelivery_Order_AGW(
              this.props.dispatch,
              {
                note:this.props.note,
                draft:draft,
                referenceId: this.props.referenceID,
                codAmount: Number(this.props.codAmount),
                isScheduled: CheckIsScheduledVar,
                customerId: get_Id(),
                uid: uuidv4(),
                pickup: {
                  addressDetail: this.props.pickup.addressDetails,
                  address: this.props.pickup.address,
                  coordinates: [
                    this.props.pickup.adresss_loc.lng,
                    this.props.pickup.adresss_loc.lat,
                  ],
                  // buildingBlock: this.props.pickup.block,
                  // customerDescription: this.props.pickup.noteForDriver,
                  // floor: this.props.pickup.floor,
                  fullName: this.props.pickup.senderFullName,
                  phone: this.props.pickup.senderPhoneNumber,
                  placeId: "",
                  // room: this.props.pickup.unit,
                  email: this.props.pickup.email,
                  completeAfter: momentJalaali(
                    this.props.pickup.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                      Number(this.props.pickup.scheduleDateAfter + "000")
                    ).unix()
                    : 0,
                  completeBefore: momentJalaali(
                    this.props.pickup.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                      Number(this.props.pickup.scheduleDateBefore + "000")
                    ).unix()
                    : 0,
                },
                delivery: {
                  addressDetail: this.props.firstDropOff.addressDetails,
                  address: this.props.firstDropOff.address,
                  coordinates: [
                    this.props.firstDropOff.adresss_loc.lng,
                    this.props.firstDropOff.adresss_loc.lat,
                  ],
                  // buildingBlock: this.props.firstDropOff.block,
                  // floor: this.props.firstDropOff.floor,
                  fullName: this.props.firstDropOff.recieverFullName,
                  phone: this.props.firstDropOff.recieverPhoneNumber,
                  placeId: "",
                  // room: this.props.firstDropOff.unit,
                  email: this.props.firstDropOff.email,
                  // customerDescription: this.props.firstDropOff.noteForDriver,
                  completeAfter: momentJalaali(
                    this.props.firstDropOff.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                      Number(
                        this.props.firstDropOff.scheduleDateAfter + "000"
                      )
                    ).unix()
                    : 0,
                  completeBefore: momentJalaali(
                    this.props.firstDropOff.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                      Number(
                        this.props.firstDropOff.scheduleDateBefore + "000"
                      )
                    ).unix()
                    : 0,
                },

                vehicleType: this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.id ? {
                  id:
                    this.props.auto_activeVehicleType &&
                    this.props.auto_activeVehicleType.id,
                  options: [
                    ...this.props.driveroptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "" };
                    }),
                  ],
                } : null,
                service: {
                  id: this.props.serviceactiveVehicleType.id,
                  options: [
                    ...this.props.serviceoptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id : "", inputValue: op.inputValue ? op.inputValue : null };
                    }),
                  ],
                },
                paymentMethod: this.props.paymentMethod,
                paymentSide: this.props.paymentSide,
                paymentProvider: this.props.paymentProvider,
                cardInfo: this.props.cardInfo,
                paymentMethodId: this.props.paymentMethodId,
                promoCode: this.props.promoCodeValid
                  ? this.props.promoCode
                  : "",
                // "paymentMethodId": null,
                // "paymentProvider": null,
                // "cardInfo": null,
              },
              {
                service: {
                  icon: this.props.serviceactiveVehicleType.icon,
                  title: this.props.serviceactiveVehicleType.title,
                },
                vehicletype: this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.id ? {
                  icon: this.props.auto_activeVehicleType.icon,
                  title: this.props.auto_activeVehicleType.title,
                } : null,
              },
              (serverRes) => {
                this.handleScheduleMinimumBalance(serverRes);
              }
            );
          }
        }
      );
      localStorage.removeItem("draft");
    }
  };

  render() {
    let priceObject = this.props.price.price;
    let pricePromoCodeValid = this.props.promoCodeValid
      ? this.props.promoAmount > 0
      : false;
    let priceDone = this.props.price.done;

    let isPickupDelivery = this.props.serviceactiveVehicleType?.orderTypes.indexOf("PickupDelivery") > -1


    return (
      <nRFooter >

        {this.props.price.loading && <costConrainer>
          <cost>
            <ProgressIndicator theme="On_Light" style={{ width: "24px", height: "24px" }} />
          </cost>
        </costConrainer>}

        {((!priceDone && !this.props.price.loading) && <costConrainer>
          <cost>
            <Typography weight="Body_Middle_Medium" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "8px", direction: "initial" }} text={<> <IconProviderSvgModifier style={{ float: "inlineStart" }} Icon={INFO_CIRCLE} /> {this.props.t("Fill in or Select")} <span style={{ color: "red" }}>*</span> {this.props.t("Fields")}</>} />
          </cost>
        </costConrainer>)

        }

        <div className="fpefpnfeof">
          <div  className={(priceDone && !this.props.price.loading) &&styles.dwsdiwhdnsw}> 

            {/* {this.props.price.loading ? (
          <costConrainer>
            <cost>
              <Trans i18nKey="poperLoading"> </Trans>
            </cost>
          </costConrainer>
        ) : priceDone ? (
          pricePromoCodeValid ? (
            <costPromoConrainer>
              <cost>
                {Number(
                  this.props.price.price.price -
                  this.props.promoAmount
                ).toLocaleString(get_lang(), {
                  style: "currency",
                  currency: this.props.price.price.currencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </cost>

              <cost style={{ fontSize: "13px", color: "red", textDecoration: "line-through" }}>
                {Number(
                  this.props.price.price.price
                ).toLocaleString(get_lang(), {
                  style: "currency",
                  currency: this.props.price.price.currencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </cost>

              <costwopromo>
                {Number(this.props.price.price.price).toLocaleString(
                  get_lang(),
                  {
                    style: "currency",
                    currency: this.props.price.price.currencyCode,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </costwopromo>
            </costPromoConrainer>
          ) : (
              <costConrainer>
                <cost>
                  <Trans i18nKey="poperLoading"> </Trans>
                </cost>
                <price><Trans i18nKey="Price"> </Trans></price>
              </costConrainer>
            ) : priceDone ? (
              pricePromoCodeValid ? (
                <costPromoConrainer>
                  <cost>
                    {Number(
                      this.props.price.price.price -
                      this.props.promoAmount
                    ).toLocaleString(get_lang(), {
                      style: "currency",
                      currency: this.props.price.price.currencyCode,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </cost>
                <price><Trans i18nKey="Price"> </Trans></price>
              </costConrainer>
            )
        ) : null } */}

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "12px", float: "inline-start" }}>
              {(!this.props.price.loading && priceDone) && <ChangeMethod priceText={pricePromoCodeValid ? Number(
                this.props.price.price.price -
                this.props.promoAmount
              ).toLocaleString(get_lang(), {
                style: "currency",
                currency: this.props.price.price.currencyCode,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) : Number(this.props.price.price.price).toLocaleString(
                get_lang(),
                {
                  style: "currency",
                  currency: this.props.price.price.currencyCode,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )} />}

              {(!this.props.price.loading && priceDone) && <PromotionCode />}
            </div>



          </div>
     

            {/* <div className={styles.footerbtnCreate}>
              <div>
              <Typography weight="Body_Middle_Bold" text={this.props.t("confirm")}  />
              </div>

              <IconProviderSvgCustomColor  Icon={Arrow_down_bold_white} size="12" style={{ width: "10px", height: "10px" }} />
            </div> */}


            <ConfirmButton title={isPickupDelivery ? "confirm" : "CreateOrder"}  className={styles.footerbtnCreate} style={{marginTop:"15px" ,marginInlineEnd:"16px",float:"inline-end"}} isDisabled={!this.props.sendOrder.canSaveOrder ||
                this.props.sendOrder.loading} placement="topEnd" text="Create" size="Medium" titleOnClick={() => { this.SendOrder(false) }}
              data={isPickupDelivery ? [{ "title": this.props.t("Draft"), onclick: ()=>{ this.SendOrder(true) }}] : null} /> 

{/* 
       {isPickupDelivery ? <> <sendOrder
            className={`${
              (!this.props.sendOrder.canSaveOrder ||
                this.props.sendOrder.loading) &&
              "grey"
              }`}
              onClick={  ()=>{(this.props.sendOrder.canSaveOrder &&
                !this.props.sendOrder.loading) && this.SendOrder(false)} }
                style={{padding:"7px 16px 0px 16px",width:"auto"}}
          >
            {this.props.sendOrder.loading ? (
              <Trans i18nKey="poperLoading"> </Trans>
            ) : (
                <Trans i18nKey={"confirm"}> </Trans>
              )}
            </sendOrder>
              <sendOrder

                style={{ padding: "6.5px 16px 0px", width: "auto", 
                  backgroundColor: "rgba(128, 128, 128, 0.06)",
                   border: "1px solid rgba(128, 128, 128, 0.2)", 
                   opacity: (!this.props.sendOrder.canSaveOrder || this.props.sendOrder.loading) && ".2",
                   backgroundImage: `linear-gradient(to top,${global.config.colors.secondaryColor}, ${global.config.colors.secondaryLightColor})`,
                   color: `${global.config.colors.secondaryTextColor}`
                  }}

                onClick={() => {
                  (this.props.sendOrder.canSaveOrder &&
                    !this.props.sendOrder.loading) && this.SendOrder(true)
                }}
              >
                {this.props.sendOrder.loading ? (
                  <Trans i18nKey="poperLoading"> </Trans>
                ) : (
                  <Trans i18nKey={"Draft"}> </Trans>
                )}
              </sendOrder>
            </> : <sendOrder
              className={`${(!this.props.sendOrder.canSaveOrder ||
                  this.props.sendOrder.loading) &&
                "grey"
                }`}
              onClick={() => {
                (this.props.sendOrder.canSaveOrder &&
                  !this.props.sendOrder.loading) && this.SendOrder(false)
              }}
              style={{ padding: "7px 16px 0px 16px", width: "auto" }}
            >
              {this.props.sendOrder.loading ? (
                <Trans i18nKey="poperLoading"> </Trans>
              ) : (
                <Trans i18nKey={"SendOrder"}> </Trans>
              )}
          </sendOrder> } */}
   
       
        </div>
        <InnerDialoge
          showpoper={this.state.showErrorScheduleMinimumBalance}
          handleCollapse={this.handleCollapseScheduleMinimumBalance}
        >
          <div>
            <img className="ScheduleMinimumBalanceIcon" src={iclowcredit} />
            <p className={"ScheduleMinimumBalanceTitle"}>  <Trans i18nKey="Increaseyourbalance"> </Trans> </p>
            <p className={"ScheduleMinimumBalanceDescription"}> {this.state.serverResponse} </p>
            <p className={"ScheduleMinimumBalanceButton"} onClick={this.handleOpenWallet}> <Trans i18nKey="AddBalance"> </Trans> </p>

          </div>
        </InnerDialoge>
        <Wallet ShowComponent={false} OpenDialoge={this.state.OpenWallet} prefered={0} onClose={() => this.handleOpenWallet(false)} />

      </nRFooter>
    );
  }
}


function InnerDialoge(props) {
  if (!isDesktop && !isTablet) {
    return (
      props.showpoper ?
        <BottomSheet
          isExpandable={false}
          customHeight={268}
          onClose={props.handleCollapse}
          backdropStyle={{ background: "rgb(13 13 13 / 55%)" }}
          containerStyle={{ borderRadius: "8px" }}
        >
          {props.children}
        </BottomSheet>
        :
        null
    );
  } else {
    return (
      <Dialog open={props.showpoper}
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus >
        <Paper
          style={{ width: "376px", height: "264px", borderRadius: "8px", overflow: "hidden" }}>
          <ClickAwayListener onClickAway={props.handleCollapse}>
            {props.children}
          </ClickAwayListener>
        </Paper>
      </Dialog>
    );
  }
}



const mapStateToProps = (state) => ({
  price: state.NewOrder.newOrder.price,
  sendOrder: state.NewOrder.newOrder.sendOrder,
  codAmount: state.NewOrder.newOrder.codAmount,
  referenceID: state.NewOrder.newOrder.referenceID,
  promoCodeValid: state.NewOrder.newOrder.promotionCode.valid,
  promoCode: state.NewOrder.newOrder.promotionCode.code,
  promoAmount: state.NewOrder.newOrder.promotionCode.data ? state.NewOrder.newOrder.promotionCode.data.promoAmount : 0,
  customer: state.NewOrder.newOrder.customer,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  paymentProvider: state.NewOrder.newOrder.paymentMethod.paymentProvider,
  cardInfo: state.NewOrder.newOrder.paymentMethod.cardInfo,
  paymentMethodId: state.NewOrder.newOrder.paymentMethod.paymentMethodId,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  note: state.NewOrder.newOrder.notes
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
