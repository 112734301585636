import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import Typography from '../../../design-system/lib/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Table_ClearSelectedOrders } from '../../../Redux/actions/actionNames';
// import TableEditStatusSelectedOrders from './components/editStatus';
import { v4 as uuidv4 } from 'uuid';
import { httpCustomer } from '../../../service/api/axios';
import { get_Id } from '../../../helper/UserData/userdate';
import _ from 'lodash';
import { BatchUpdateStatus } from '../../../helper/api';
import { snack } from '../../../helper/module/SnackbarUtilsConfigurator';
import TableEditStatusSelectedOrders from './components/editStatus';
import { isDesktop, isTablet } from 'react-device-detect';
import {ReactComponent as BARCODE}  from "../../../static/IconProvider/barcode.svg";
import { useTranslation } from 'react-i18next';

const FileDownload = require('js-file-download');


function TableSelectedOrders() {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const table = useSelector((state) => state.Table.tableInit)
  const [downloading, setDownloading] = useState(false);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();


  const clearAllSelectedOrders = React.useCallback(() => {
    dispatch(Table_ClearSelectedOrders())
    table.toggleAllRowsSelected(false)

  }, [table]);


  const ShipmentLabelPdfExport = React.useCallback(async () => {

    setDownloading(true)
    httpCustomer.post('/api/v1/customer/order/shipment-label/export-pdf', {
      ids: Object.keys(selectedOrders),
      customerId: get_Id()
    }, {
      responseType: 'blob',
      headers: {
        withCredentials: true,
        'x-bypass-auth': true
      }
    }).then(function (response) {
      console.log(response);
      // window.open(URL.createObjectURL(response.data));

      var blob = new Blob([response.data], { type: 'application/pdf' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = response.headers["content-disposition"].replace("attachment; filename=", '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloading(false)


    }).catch(function (err) {
      console.log(err);
      setDownloading(false)

    })

    // let Order = new OrderApi()
    // let data = await Order.ShipmentLabelPdfExport({
    //   "ids": Object.keys(selectedOrders)
    // })

    // if (data) {
    //   var blob = new Blob([data], { type: 'application/pdf' });
    //   console.log("BatchUpdateStatus ", data)
    //   FileDownload(blob, `ShipmentLabelPdfExport-${uuidv4()}.pdf`)

    // }
  }, [selectedOrders]);


  const canDraft = React.useCallback(() => {
    let status = ['Draft', 'Confirmed', 'CustomerCanceled']
    return _.toArray(selectedOrders).every(obj => status.indexOf(obj.status) > -1);
  }, [selectedOrders]);

  const canConfirmed = React.useCallback(() => {
    let status = ['Draft', 'Confirmed', 'CustomerCanceled']
    return _.toArray(selectedOrders).every(obj => status.indexOf(obj.status) > -1);
  }, [selectedOrders]);


  const canCustomerCanceled = React.useCallback(() => {
    let status = ['Draft', 'Confirmed', 'CustomerCanceled', 'PickupRouted', 'ReadyForPickup']
    return _.toArray(selectedOrders).every(obj => status.indexOf(obj.status) > -1);
  }, [selectedOrders]);


  const handleSave = React.useCallback(async (selectedStatus) => {
    // setLoading(true)
    let data = await BatchUpdateStatus({
      "ids": Object.keys(selectedOrders),
      "status": selectedStatus,
      "customerId": get_Id(),
    })

    if (data) {
      console.log("BatchUpdateStatus ", data)
      // setLoading(false)
      // setOpenEditStatus(false)
      snack.BatchOperationalSnackbar(JSON.stringify({
        "id": data.id,
        "status": data.status,
        "action": data.action,
        "totalRecordsCount": data.totalRecordsCount,
        "successfulRecordsCount": data.successfulRecordsCount,
        "failedRecordsCount": data.failedRecordsCount,
        "lastProcessedRecordIndex": data.lastProcessedRecordIndex,
        "records": data.records,
        "createdAt": data.createdAt,
        "doneAt": data.doneAt,
      }))

    }


    dispatch(Table_ClearSelectedOrders())


  }, [selectedOrders]);


  if (Object.keys(selectedOrders).length == 0) {
    return null
  }
  return (
    <div className={styles.TableFilter}>

      <Typography weight='Body_Middle_Medium' text={`${Object.keys(selectedOrders).length} ${t("selected")}`} textColor='TextDark' />
      <Button isLoading={downloading} size='Medium' type='Secondary' text={!isDesktop && !isTablet ? null :t('Shipmentlabel')} IconComponent={BARCODE} onClick={ShipmentLabelPdfExport} />
      {/* {canDraft() && <Button size='Medium' type='Secondary' text='Draft' icon={pen} onClick={()=>{handleSave('Draft')}} /> }
      {canConfirmed() && <Button size='Medium' type='Secondary' text='Confirm' icon={ic_check_circle}  onClick={()=>{handleSave('Confirmed')}} />}
      {canCustomerCanceled() && <Button size='Medium' type='Secondary' text='Cancel' icon={ic_close_red}  onClick={()=>{handleSave('CustomerCanceled')}} />} */}
      <TableEditStatusSelectedOrders />
      {/* <Button size='Medium' type='Secondary' text='Edit Status' icon={Edit} onClick={()=>{handleEditStatus()}} /> */}
      {/* <TableEditStatusSelectedOrders /> */}
      <Typography weight='Body_Middle_Medium' text={t('ClearAll')} className={styles.Clear + " inputFilterDesignSystemApply"} onClick={clearAllSelectedOrders} />

      {/* <Button size='Medium' type='Secondary' text='Date' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Order Type' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Status' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Stage' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Zone' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Route' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='More filters' icon={Plus_Dark} />

        <Button size='Medium' type='Secondary' icon={Search_Table} style={{marginInlineStart:"auto"}} />
        <div className={styles.divider} />
        <Button size='Medium' type='Secondary' icon={Refresh_Table} style={{backgroundColor:"unset"}} />
        <Button size='Medium' type='Secondary' icon={Settings} style={{backgroundColor:"unset"}}/> */}

      {/* <TableEditStatusSelectedOrders /> */}
    </div>
  )
}


export default TableSelectedOrders