import React, { useCallback, useEffect, useRef } from 'react'
import styles from './style.module.css';
import Typography from '../../../design-system/lib/Typography';
import IconProvider from '../../../design-system/lib/IconProvider';
import { Ic_danger } from '../../../static';
import Button from '../../../design-system/lib/Button';
import { getTableOrderList } from '../../shared/table/action/getTableOrderList';
import { useTranslation } from 'react-i18next';







function TableError() {
  const { t, i18n } = useTranslation();

  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100vw", flexDirection: 'column', transition: "all .3s ease-in-out" ,justifyContent:"center",alignItems:"center"}}>
          <div className={styles.dialog}>
            <IconProvider Icon={Ic_danger} style={{marginBottom:"16px"}} />
            <Typography weight='Body_Middle_Bold' text={t('Errorinloadingdata')} style={{textAlign:"center" , marginBottom:"4px",color:"rgba(36, 36, 36, 1)"}} />
            <Typography weight='Body_Tiny_Regular' text={t('Therewasaproblemloadingdatapleasetryagainorcontactsupport')} style={{textAlign:"center",marginBottom:"16px",color:"rgba(128, 128, 128, 1)"}} />
            <Button size='Medium' type='Primary' text={t('Tryagain')} className='NewOrderBtnsHeader' onClick={()=>getTableOrderList(null , null , )}/>
          </div>
    </div>
  )
}
export default TableError