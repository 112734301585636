import { useSnackbar } from 'notistack3'
import React from 'react'
// import i18n from '../service/i18/i18n';


const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};


let useSnackbarRef ;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef}/>;
}

export const snack = {
  // success_i18(msg) {
  //   this.toast(i18n.t(msg), 'success')
  // },
  success(msg) {
    this.toast(msg, 'success')
  },
  // warning_i18(msg) {
  //   this.toast(i18n.t(msg), 'warning')
  // },
  warning(msg) {
    this.toast(msg, 'warning')
  },
  // info_i18(msg) {
  //   this.toast(i18n.t(msg), 'info')
  // },
  info(msg) {
    this.toast(msg, 'info')
  },
  // error_i18(msg) {
  //   this.toast(i18n.t(msg), 'error')
  // },
  error(msg) {
    this.toast(msg, 'error')
  },
  BatchOperationalSnackbar(msg) {
    this.batchToast(msg, 'BatchOperationalSnackbar',true)
  },

  ErrorTitleDescSnackbar(msg) {
    this.batchToast(msg, 'ErrorTitleDescSnackbar',true)
  },
  toast(msg, variant = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant , autoHideDuration: 2500 })
  },
  batchToast(msg, variant = 'default' , persist) {
    useSnackbarRef.enqueueSnackbar(msg, { variant , persist  })
  }
}