import React, { useCallback, useRef, memo, forwardRef, useState, useEffect } from 'react'
import styles from './style.module.css';
import { SnackbarContent ,closeSnackbar} from "notistack3";
import Typography from '../../../design-system/lib/Typography';
import { useDispatch } from 'react-redux';
import { Table_setSelectedBulkEditStatusSummary } from '../../../Redux/actions/actionNames';
import { close_white } from '../../../static';
import BatchService from '../../../service/api/batch';





export const ErrorTitleDescSnackbar = forwardRef((props, forwardedRef) => {
  const { message, variant , id} = props;
  const [batchData, setBatchData] = useState(JSON.parse(message));
  const dispatch = useDispatch();



  const openSummary = React.useCallback(() => {
    closeSnackbar(id)
  }, [id]);


  return (
    <SnackbarContent
      ref={forwardedRef}
      style={{
        height: "46px",
        backgroundColor: "rgba(187, 18, 0, 1)",
        borderRadius: "8px",
        boxShadow: "0px 1px 6px 0px rgba(128, 128, 128, 0.16)",
        padding:"12px 16px 12px",
        display:"flex",
        flexDirection:"row",
        gap:"16px",
        justifyContent:"center",
        alignItems:"center"
      }}
    >


            <img src={close_white} style={{width:"24px",height:"24px", cursor:"pointer"}} onClick={openSummary} />
            
            <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start",gap:"3px" , flex:"1"}}>
              <Typography weight="Body_Middle_Medium" text={batchData.title} style={{color:"white"}}  />
              <Typography weight="Body_Tiny_Regular" text={batchData.description} style={{color:"white"}}  />

            </div>
          
  
    </SnackbarContent>
  );
});

